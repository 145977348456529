<template>
    <div class="container_one" style="background-color: #F6F7F8;">
    
        
        <el-row >
        <el-col :span="24"><div class="lsbg">

            <div class="text1" style="text-align: left; padding:10px;width: 400px;">
            <span style="font-size:35px;color:orange;"><b>数字化企业</b><br><br></span>
            <span style="font-size:15px;color:#ffffff;">全方位数字化流程办公，让营销，运营，管理战略，协作互通，积累数字资产，为企业运营管理决策提供依据<br></span>
            </div>
            <!-- <img src="..\\src\\static\\web\\解决方案-零售行业\\编组 21.png" width="100%"> -->
        </div></el-col>
        </el-row><br><br>

        <el-row >
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>产品应用</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">PRODUCT APPLICATION<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        
        <el-row :gutter="100" >
        <el-col :span="4"><div>&emsp;</div></el-col>

        <el-col :span="4">  
            <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\1.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">项目管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>项目立项、项目过程管理、项目档案</span>
            </div>
           </div>
        </el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\2.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">资产管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>资产领用、资产购置、资产调拨</span>
            </div>
           </div></el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\3.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">采购管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>采购模式、采购类型、采购过程管控</span>
            </div>
           </div></el-col>
            <el-col :span="4"><div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\4.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">供应商管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>评判、资质、筛选、档案</span>
            </div>
           </div></el-col>


        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="100">
        <el-col :span="4"><div>&emsp;</div></el-col>

        <el-col :span="4">  
            <div style="overflow: hidden; text-overflow: ellipsis;;width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\5.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">人事管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>劳动关系、薪资福利、考勤管理</span>
            </div>
           </div>
        </el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\6.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">客户管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>客户管理、商机与销售、产品管理</span>
            </div>
           </div></el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\7.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">合同管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>合同签订、合同履约、合同台账</span>
            </div>
           </div></el-col>
            <el-col :span="4"><div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\8.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">知识管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>知识门户、知识地图、知识检索</span>
            </div>
           </div></el-col>


        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="100">
        <el-col :span="4"><div>&emsp;</div></el-col>
        <el-col :span="4"><div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\9.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">协同BPM</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>流程仿真、流程绩效、流程集成</span>
            </div>
           </div></el-col>
        <el-col :span="4">  
            <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\10.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">移动办公</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>移动审批、移动门户、移动语音助手</span>
            </div>
           </div>
        </el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\11.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">集团管控</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>战略管控、组织管控、财务管控</span>
            </div>
           </div></el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\12.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">组织管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>主数据、集权模式、分权模式、集分权结合</span>
            </div>
           </div></el-col>
           


        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="100">
        <el-col :span="4"><div>&emsp;</div></el-col>

        <el-col :span="4">  
            <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\13.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">统一门户</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>角色门户、业务门户、移动门户</span>
            </div>
           </div>
        </el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\14.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">公文管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>公文流转、智能填单、移动办文</span>
            </div>
           </div></el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\15.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">会议管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>会前、会中、会后的闭环管理</span>
            </div>
           </div></el-col>
            <el-col :span="4"><div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\16.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">目标管理</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>目标制定、执行、优化、检查</span>
            </div>
           </div></el-col>


        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="100">
        <el-col :span="4"><div>&emsp;</div></el-col>

        <el-col :span="4">  
            <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\17.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">组织绩效</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>个人绩效、组织绩效、流程绩效</span>
            </div>
           </div>
        </el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\18.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">报表中心</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>财务报表、资产报表、销售报表</span>
            </div>
           </div></el-col>
        <el-col :span="4"> <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\19.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">数据平台</span>
                </div>
                
                <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>数据集成、数据分析、数据安全</span>
            </div>
           </div></el-col>
            <el-col :span="4"><div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:150px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <br>
                    <img src="..\\src\\static\\web\\解决方案-零售行业\\20.png" width="50px" height="50px">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 12px;color: rgb(85, 85, 85);">数字化</span><br>
                    <span style="font-size: 20px;">系统集成</span>
                </div>
                
                    <span style="font-size:13px;color:#686767;letter-spacing: 2px;line-height: 1.5;"><br><br><br><br><br>ERP集成、PLM集成、商旅集成</span>
            </div>
           </div></el-col>


        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>产品优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">PRODUCT SUPERIORITY<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>



        <el-row :gutter="160">
        <el-col :span="4"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:250px;height:330px;padding:20px">
            <br><br>
            
               <img src="..\\src\\static\\web\\解决方案-零售行业\\编组 22@2x.png" width="120px" height="120px">
           
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">战略管理</span><br><br>
                <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">面向组织高层管理者</span><br><br>
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">确定战略与目标，基于数字化运营企业，帮助其定方向、定目标、配资源看全局、做决策、做指挥</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;200px;border-radius: 10px;width:250px;height:330px;padding:20px">
            <br><br>
          
               <img src="..\\src\\static\\web\\解决方案-零售行业\\编组 19@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">运营管控</span><br><br>
                <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">面向全员、各层管理者</span><br><br>
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">通过流程标准化、风险控制、流程创新优化，实现对业务过程进行有效管理，保证流程的规范、标准、合规、可控</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:250px;height:330px;padding:20px">
            <br><br>
           
               <img src="..\\src\\static\\web\\解决方案-零售行业\\编组 17@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">工作赋能</span><br><br>
                <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">面向全体员工、生态伙伴</span><br><br>
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">赋能团队生产力、赋能团队协作效率实现数字办公、混合办公，开启组织数字化办公新范式</span></div>
                
            </div>
            </div></el-col>
            <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:250px;height:330px;padding:20px">
            <br><br>
           
            <img src="..\\src\\static\\web\\解决方案-零售行业\\09d8c14437575584d8b1b9ba2e68386.png" width="120px" height="120px">
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">共享服务</span><br><br>
                <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">面向全体员工、职能部门、生态伙伴</span><br><br>
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">将职能管理以共享服务方式提供、以提升职能效率和集约降本、促进业务发展、支持组织变化</span></div>
                
            </div>
            </div></el-col>


        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>






       
    
    </div>
    </template>
      
    <style>
    .lsbg{
        height: 400px;
        position: relative;
        background-image: url('../static/web/解决方案-零售行业/编组 21.png');
        background-size: cover;
        background-position: center;
        .text1{
            position: absolute;
            top: 25%;
            left: 25%;
            letter-spacing: 2px;
            line-height: 1.5;
        }
    }
    
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      