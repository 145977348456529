<template>
    <el-container style="background-color:#f3f0f0d3">
    <el-header style="background-color:#f3f0f0d3;height: 80px;">
        <br>
        <el-col :span="15"><div>
            <el-input
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="input2">
            </el-input>
        </div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message-solid" style="color: black;"></i></div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message" style="color: black;"></i></div></el-col>
        <el-col :span="3">
            
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="change-icon"><i class="el-icon-more"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus"><a href="/home">首页</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/rzh">人智汇</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dangjian">党建</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/my">关于我们</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dingzhi">定制开发</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/serve">服务</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/car">解决方案-汽车行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/architecture">解决方案-建筑行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/financial">解决方案-金融行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/retail">解决方案-零售行业</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            
        </el-col>
    </el-header>
    <el-main style="background-color: #F6F7F8;padding:0;">
        <el-row :gutter="20">
        <el-col :span="24"><div class="grid-content bg-purple"><img src="..\\src\\static\\党建\\dang.png" width="100%" height="100%"></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="18">
                <div style="text-align: left;">
                    <h1><font size="10">党建信息化</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">SOLUTION</font>
                </div>
            </el-col>
            <el-col :span="5"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;font-weight: 500;">
                        采用信息技术进行前端界面设计以及实现，
                        构建了党校培训过程管理系统;强化了党校培训过程管理，增强党校培训的
                        吸引力与有效性同时丰富党建信息化平台内容，推动党建信息化水平。
                    </h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>


        <!-- <el-row :gutter="20">
            <el-col :span="24" >
                <div class="centered-container container_img1 "  width="100%" height="100%">
                        <br><br><br>
                        <span><img src="..\\src\\static\\党建\\材料规范备份.png"></span>
                        <br><br>
                        <span style="font-size: 24px; font-weight: bold;">规范化</span>
                        规范基层党建内容、<br>
                        明确基层党建程序、<br>
                        完善基层考核机制。
                        <br><br><br><br><br><br><br><br><br>  
                </div>
            </el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24" height="800px">
                <div class="centered-container container_img1"  width="100%" height="100%">
                    <br><br><br>
                    <span><img src="..\\src\\static\\党建\\支部规范化备份.png"></span>
                    <br><br>
                        <span style="font-size: 24px; font-weight: bold;">扁平化</span>
                        扁平化信息支撑，<br>
                        强化层级管理，<br>
                        责任层层落实
                        <br><br><br><br><br><br><br><br><br>
                </div>
            </el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24"  height="800px">
                <div class="centered-container container_img1"  width="100%" height="100%">
                    <br><br><br>
                    <span><img src="..\\src\\static\\党建\\动态.png"></span>
                    <br>
                        <span style="font-size: 24px; font-weight: bold;">动态化</span>
                        工作、管理、考核<br>
                        实时跟进、更新，<br>
                        可追溯，易考察<br><br><br><br><br><br><br><br><br>
                        
                </div>
            </el-col>
        </el-row><br> -->

        <el-row :gutter="20">
            <el-col :span="24">
                <img src="..\\src\\static\\党建\\d6ede93667af0a07eb6591d59b343e7.png" >
            </el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">功能服务</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">FUNCTIONAL SERVICE</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;font-weight: 500;">
                        党建信息化管理平台是基于云计算技术的解决方案，
                        旨在打造一个集党建宣传、党建工作管理、党员服务、党员学习、互动交流、数据
                        统计分析为一体的智慧化平台。通过统一标准、整合资源、优化业务流程、共享网
                        络服务，使得党建工作信息化投资更加节约、性能更加优越、信息更加集成、业务
                        更加协同。
                    </h4>
                    
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24">
                <!-- <div>
                    <el-steps direction="vertical" :active="0" class="stepsStyle">
                        <el-step title="">

                            <template slot="description">
                                <div height="120px" width="80%" style="background-color:crimson;color:rgb(233, 226, 227);" >
                                    档案管理、组织关系转接、困难党员管理、流动党员管理、党员发展管理
                                </div>
                                <div height="120px" width="80px">
                                    改变以往手工操作、人工管理等存在的随意性、模糊性不足，通过量化计分、实时智能分析、自动提醒服务等实现了党建管理的精确化、智能化、人文化和科学化
                                </div>
                            </template>
                           
                        </el-step>

                        <el-step title="">
                            <template slot="description">
                                <div height="120px" style="background-color:crimson;color:rgb(233, 226, 227);" >
                                    “三会一课”、民主生活会、志愿活动管理、党费管理、积分管理
                                </div>
                                <div height="120px">
                                    打破以往党内活动相对封闭和自循环的状态，让党员和党组织可以通过网站、手机等方式能够随时网上征集意见、参与组织生活、学习交流讨论等
                                </div>
                            </template>
                        </el-step>

                        <el-step title="">
                            <template slot="description">
                                <div height="120px" style="background-color:crimson;color:rgb(233, 226, 227);" >
                                     工作台、统计报表、民主评议
                                </div>
                                <div height="120px">
                                    改变传统抽样调查、查看台账等传统、机械方式，通过实时感知、在线提交、快速动员等实现了对基层党建的动态跟踪和针对性管理服务
                                </div>
                            </template>
                        </el-step>

                        <el-step title="">
                            <template slot="description">
                                <div height="120px" style="background-color:crimson;color:rgb(233, 226, 227);" >
                                    两学一做、专题学习、课程管理、考试管理、学分管理
                                </div>
                                <div height="120px">
                                    支持视频、文章、WORD、PPT、PDF多种在线学习方式，保留每次学习记录，通过在线学习大数据反应党员在线学习情况。
                                </div>
                            </template>
                        </el-step>

                    </el-steps>
                </div> -->
               
                <img src="..\\src\\static\\党建\\ecc2e745fe705052b747571be3a580d.png" >
            </el-col>
        </el-row><br>
        
        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">核心优势</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CORE ADVANTAGES</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>
    <div class="dt">
        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid rgb(114, 7, 21); border-radius: 10px; padding: 10px;">
                    
                        
                        <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\党建\\编组 23@3x.png" class="left-img">
                            <span>360度大数据洞察</span>
                            <img src="..\\src\\static\\党建\\编组 13备份@3x.png"  class="right-img">
                            
                        </div><hr>
                        
                        充分挖掘各类信息资源，全面覆盖管理建设需求<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid rgb(114, 7, 21); border-radius: 10px; padding: 10px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\党建\\编组 24备份 2@3x.png" class="left-img">
                            <span>可追溯式查询管理</span>
                            <img src="..\\src\\static\\党建\\编组 22备份@3x.png"  class="right-img">
                            
                        </div><hr>
                        
                        全过程监督，决策留痕，风险可控<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>
    </div>
        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid rgb(114, 7, 21); border-radius: 10px; padding: 10px;">
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                         
                        <img src="..\\src\\static\\党建\\编组 27@3x.png" class="left-img">
                            <span>可视化态势感知</span>
                            <img src="..\\src\\static\\党建\\编组 23备份 2@3x.png"  class="right-img">
                            
                        </div><hr>

                        
                        全方位呈现管理和服务整体状态和态势<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

      

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">联系我们</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CONTACT US</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">公司名称</h4>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的姓名</h4>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的电话</h4>
                <el-input v-model="input" placeholder="请输入联系电话"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">您的电子邮箱</h4>
                    <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>
        
        
        
        <!-- <div style="background-color:#0e0c0ce1;">
            <div class="container">
                &nbsp;&nbsp;<img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&nbsp;尽大至微，卓然天成</h3>
            </div>
            <hr>
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

</div> -->

    </el-main>
    <el-footer style="height: 300px;background-color: #2B2C31">
        <div >
            <div class="container">
                <img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&emsp;&emsp;尽大至微，卓然天成</h3>
        </div>
            <hr size="1">
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

        </div>
    </el-footer>
    </el-container>
</template>
  
  <script>

  export default {
    data() {
      return {

      }
    },
    

  }
  </script>

  <style>
.dt{
    background-image: url('../static/党建/编组备份@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.change-icon{
    font-size:35px;
}

.centered-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center; /* 将内容垂直置中 */
  }

  .box {
    width: 100px;
    height: 100px;
    /* background-color: #EAE5F6; */
    }
.container {
display: flex;
align-items: center; /* 垂直居中对齐 */
}
.stepsStyle{
   width: 100%;
   ::v-deep{

     .el-step__title.is-process,.el-step__title.is-wait{
       color: rgb(233, 70, 70) !important;
     }
     .el-step__head.is-process,.el-step__heads.is-wait{
        .el-step__icon.is-text{    /*//这里设置的是下一步和等待状态里的文字颜色 */
         color: rgb(233, 70, 70) !important;
         border-color: rgb(233, 70, 70) !important;
       }
     }
     .el-step__head.is-finish{
       color: rgb(233, 70, 70) !important;
       .el-step__icon.is-text{   /*//这里设置的是已完成状态里的文字颜色 */
         color: rgb(233, 70, 70);
         border-color: rgb(233, 70, 70);
       }
     }
     .el-step__title.is-finish{
       color: rgb(233, 70, 70);
     }
     .el-step__line-inner{/*//这里设置的是完成后过渡线条颜色*/
       border-color: rgb(233, 70, 70);
     }
     .el-step__head.is-success,.el-step__title.is-success{/*//这里设置的是完成后的颜色*/
       color:rgb(233, 70, 70);
     }
   }
 }
 a {
    text-decoration: none;
    color: #000;
  }
  .container_img1 {
 background-image: url("../static/党建/编组 65备份 2@2x.png");
 background-size: cover;
    background-position: center;

    width: 100%;
    height: 100%;
}
img {
    max-width: 100%;
    max-height: 100%;
  }
  .left-img {
    float: left;
    margin-right: 20px;
    /* position: absolute; */
    /* bottom: 10;  */
    /* left: 1; */
  }
  .right-img {
    float: right;
    margin-left: 20px;
    /* position: absolute; */
    /* bottom: 10; */
    /* right: 0; */
  }
  .square {
    width: 200px;
    background-color: #ccc;
    padding-top: 100%; /* 将 padding-top 设置为宽度的百分比 */
    position: relative; /* 为了让内容在正方形内居中显示，需要将元素设置为相对定位 */
    overflow: hidden; /* 防止内容溢出 */
    transition: all 0.3s ease-in-out; /* 添加过渡效果 */
  }

  .square:hover {
    transform: scale(1.2); /* 当鼠标悬停时，放大正方形 */
  }

  .square-content {
    position: absolute; /* 绝对定位元素，使其在正方形内居中显示 */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>
  