<template>
    <el-container style="background-color:#f3f0f0d3">
    <el-header style="background-color:#f3f0f0d3;height: 80px;">
        <br>
        <el-col :span="15"><div>
            <el-input
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="input2">
            </el-input>
        </div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message-solid" style="color: black;"></i></div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message" style="color: black;"></i></div></el-col>
        <el-col :span="3">
            
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="change-icon"><i class="el-icon-more"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus"><a href="/home">首页</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/rzh">人智汇</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dangjian">党建</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/my">关于我们</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dingzhi">定制开发</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/serve">服务</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/car">解决方案-汽车行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/architecture">解决方案-建筑行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/financial">解决方案-金融行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/retail">解决方案-零售行业</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            
        </el-col>
    </el-header>
    <el-main style="background-color: rgb(250, 248, 248);padding:0;">
        <el-row :gutter="20">
        <el-col :span="24"><div class="grid-content bg-purple"><img src="..\\src\\static\\零售\\c9434f902ce7b1c7b95e703dde99735.png" width="100%" height="100%"></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">前景分析</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">PROSPECT ANALYSIS</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

                <div class="parent">
                    
                <div class="child1">
                    
                </div>
                <div class="child2">
                    <img src="..\\src\\static\\汽车\\编组 13@3x.png"  class="right-img2">
                    <div style="padding: 10px;text-align: left;">
                        <h2 style="text-align: left;">门店销量缩水</h2>
                        <span>
                            由于近年来电商行业迅速发展，线下门店不断被电商挤压，导致销量、利润严重缩水
                        </span> 
                    </div>
                    
                </div> 
                    
                
                </div>
            
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

                <div class="parent1">
                <div class="child3"></div>
                <div class="child4">
                    <img src="..\\src\\static\\汽车\\编组 22备份@3x.png"  class="left-img2">
                    <div style="padding: 10px;text-align: left;">
                        <h2 style="text-align: right;">门店成本转化率低</h2>
                        <span>
                            门店被人工、店铺等因素影响，导致门店成本高居不下，转化率却无法达到有效提高
                        </span> 
                    </div>
                </div> 
                </div>
            
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

            <div class="parent">
                
            <div class="child1">
                
            </div>
            <div class="child2">
                <img src="..\\src\\static\\汽车\\编组 23备份 2@3x.png"  class="right-img2">
                <div style="padding: 10px;text-align: left;">
                    <h2 style="text-align: left;">货架无法扩充品类</h2>
                    <span>
                        门店面积有限，无法扩充多元华产品导致用户粘性不高，客流量与销量不段缩水
                    </span> 
                </div>    
            </div>     
            </div>
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

        <div class="parent1">
        <div class="child3"></div>
        <div class="child4">
            <img src="..\\src\\static\\汽车\\编组 24备份 2@3x.png"  class="left-img2">
            <div style="padding: 10px;text-align: left;">
                <h2 style="text-align: right;">管理粗放促销单一</h2>
                <span>
                    大多零售类上架采用“大锅饭”式营销，不但效果低，而且费时费力，转化率也很难提高
                </span> 
            </div>
        </div> 
        </div>

        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>
        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

            <div class="parent">
                
            <div class="child1">
                
            </div>
            <div class="child2">
                <img src="..\\src\\static\\汽车\\编组 25备份@3x.png"  class="right-img2">
                <div style="padding: 10px;text-align: left;">
                    <h2 style="text-align: left;">会员粘性低</h2>
                    <span>
                        会员管理难度极大，并且会员的真正价值未被完全挖掘，导致会员粘性极低
                    </span> 
                </div>    
            </div>     
            </div>
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

        <div class="parent1">
        <div class="child3"></div>
        <div class="child4">
        <img src="..\\src\\static\\汽车\\编组 27备份@3x.png"  class="left-img2">
        <div style="padding: 10px;text-align: left;">
            <h2 style="text-align: right;">缺乏数据洞察能力</h2>
            <span>
                零售门店类的上架对整体销售、单体销售、退换货情况没有一个及时的追踪
            </span> 
        </div>
        </div> 
        </div>

        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>


        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">解决方案</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">SOLUTION</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div style="border: 1px solid rgba(15, 15, 15, 0.884); border-radius: 10px; padding:30px;">
                    <img src="..\\src\\static\\零售\\编组 23备份@3x.png" >
                    <h3 style="text-align: left;color: rgb(243, 169, 9);">千人千面</h3>
                    <h3 style="text-align: left;color: rgba(12, 12, 10, 0.945);">个性理财推荐适合的TA</h3>
                    <h4 style="text-align: left;color: rgba(39, 37, 21, 0.651);">
                        与客户沟通时一键拉出客户基本 信息、标签、历史交易记录等，在客户的角度提供方案，打造差异话理财社群
                    </h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div style="border: 1px solid rgba(15, 15, 15, 0.884); border-radius: 10px; padding: 30px;">
                    <img src="..\\src\\static\\零售\\编组 20备份@3x.png" >
                    <h3 style="text-align: left;color: rgb(243, 169, 9);">全渠道客户沉淀</h3>
                    <h3 style="text-align: left;color: rgba(12, 12, 10, 0.945);">基准挖掘理财价值</h3>
                    <h4 style="text-align: left;color: rgba(39, 37, 21, 0.651);">
                        根据客户年龄、职业、地区、理 财记录、偏好等自动或手动打标签;对行内客户信息与微信好友匹配，实时提醒
                    </h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div style="border: 1px solid rgba(15, 15, 15, 0.884); border-radius: 10px; padding: 30px;">
                    <img src="..\\src\\static\\零售\\编组 18备份@3x.png" >
                    <h3 style="text-align: left;color: rgb(243, 169, 9);">一对一服务</h3>
                    <h3 style="text-align: left;color: rgba(12, 12, 10, 0.945);">高效提升客户信任</h3>
                    <h4 style="text-align: left;color: rgba(39, 37, 21, 0.651);">
                        随时高效服务客户，帮助客户解决金融理财问题;将活动，产品系，宣传物料装进经理手机，确保产品等最快触达客户
                    </h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

       
        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">联系我们</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CONTACT US</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">公司名称</h4>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的姓名</h4>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的电话</h4>
                <el-input v-model="input" placeholder="请输入联系电话"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">您的电子邮箱</h4>
                    <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>
        
        
        
        <!-- <div style="background-color:#0e0c0ce1;">
            <div class="container">
                &nbsp;&nbsp;<img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&nbsp;尽大至微，卓然天成</h3>
            </div>
            <hr>
        <div class="container">
        <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

</div> -->

    </el-main>
    <el-footer style="height: 300px;background-color: #2B2C31">
        <div >
            <div class="container">
                <img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&emsp;&emsp;尽大至微，卓然天成</h3>
        </div>
            <hr size="1">
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

        </div>
    </el-footer>
    </el-container>
</template>
  
  <script>

  export default {
    data() {
      return {

      }
    },
    

  }
  </script>

  <style>
.change-icon{
    font-size:35px;
}

.centered-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center; /* 将内容垂直置中 */
  }

  .box {
    width: 100px;
    height: 100px;
    /* background-color: #EAE5F6; */
    }
.container {
display: flex;
align-items: center; /* 垂直居中对齐 */
}
a {
    text-decoration: none;
    color: #000;
  }
  .left-img {
    float: left;
    margin-right: 20px;
    /* position: absolute; */
    /* bottom: 10;  */
    /* left: 1; */
  }
  .right-img {
    float: right;
    margin-left: 20px;
    /* position: absolute; */
    /* bottom: 10; */
    /* right: 0; */
  }
  .left-img2 {
    /* float: left;
    margin-right: 20px;
    margin-top: 0px; */
    position: absolute;
    top: -30px; 
    left: 45px;
    width: 40px;
    height: 60px;
  }
  .right-img2 {
    /* float: right;
    margin-left: 20px;
    margin-top: 0px; */
    /* position: absolute; */
    position: absolute;
    top: -30px;
    right: 45px;
    width: 40px;
    height: 60px;
  }

  /* .parent {
  position: relative;
  height: 250px;
  width: 100%;
  .child1 {
    border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 250px;
  background-color: rgb(235, 235, 235);
  z-index: 1;
  .image{
    top: 0;
    right: 0;
  }
}
.child2 {

  border-radius: 20px;
  position: absolute;
  top: 25px;
  left: 40px;
  width: 90%;
  height: 200px;
  background-color: rgb(255, 254, 254);
  border: 1px solid orange;
  z-index: 2;
}
}





.parent1 {
  position: relative;
  height: 250px;
  width: 100%;
  .child3 {
    border-radius: 20px;
  position: absolute;
  top: 0;
  left: 40px;
  width: 90%;
  height: 250px;
  background-color: rgb(235, 235, 235);
  z-index: 1;
};
.child4 {
    border-radius: 20px;
  position: absolute;
  top: 25px;
  left: 0;
  width: 90%;
  height: 200px;
  background-color: rgb(255, 254, 254);
  border: 1px solid orange;
  z-index: 2;
}
} */



  </style>
  