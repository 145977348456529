import Vue from 'vue'
import VueRouter from 'vue-router'
// import a from '../views/a.vue'
import home from '../views/home.vue'   // 首页
import rzh from '../views/RZH.vue'   // 人智汇
import dangjian from '../views/dangjian.vue'   // 党建
import my from '../views/my.vue'   // 关于我们
import dingzhi from '../views/dingzhi.vue'   // 定制
import server from '../views/server.vue'   // 服务
import car from '../views/car.vue'   // 解决方案-汽车
import architecture from '../views/architecture.vue'   // 解决方案-建筑  
import financial from '../views/financial.vue'  // 金融 
import retail from '../views/retail.vue'  // 零售 

import web_home from '../views/web/home.vue'  // web首页
import web1 from '../components/home.vue'  // web首页
import web2 from '../components/RZH.vue'  // web人智汇
import web3 from '../components/dangjian.vue'  // web党建
import web4 from '../components/my.vue'  // web关于我们
import web5 from '../components/dingzhi.vue'  // web定制开发
import web6 from '../components/server.vue'  // web服务
import web7 from '../components/car.vue'  // web汽车
import web8 from '../components/architecture.vue'  // web建筑
import web9 from '../components/financial'  // web金融
import web10 from '../components/retail.vue'  // web零售
import web11 from '../components/HelloWorld.vue'  // web零售

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'web_home',
    component: web_home,
    meta: {
      title: '大微天成', // 设置标签页名称

    }
  },
  {
    path: '/web1',
    name: 'web1',
    component: web1
  },
  {
    path: '/web2',
    name: 'web2',
    component: web2
  },
  {
    path: '/web3',
    name: 'web3',
    component: web3
  },
  {
    path: '/web4',
    name: 'web4',
    component: web4
  },
  {
    path: '/web5',
    name: 'web5',
    component: web5
  },
  {
    path: '/web6',
    name: 'web6',
    component: web6
  },
  {
    path: '/web7',
    name: 'web7',
    component: web7
  },
  {
    path: '/web8',
    name: 'web8',
    component: web8
  },
  {
    path: '/web9',
    name: 'web9',
    component: web9
  },
  {
    path: '/web10',
    name: 'web10',
    component: web10
  },
  {
    path: '/web11',
    name: 'web11',
    component: web11
  },
  // {
  //   path: '/',
  //   name: 'a',
  //   component: a
  // },
  {
    path: '/gzh_home',
    name: 'home',
    component: home
  },
  {
    path: '/rzh',
    name: 'rzh',
    component: rzh
  },
  {
    path: '/dangjian',
    name: 'dangjian',
    component: dangjian
  },
  {
    path: '/my',
    name: 'my',
    component: my
  },
  {
    path: '/dingzhi',
    name: 'dingzhi',
    component: dingzhi
  },
  {
    path: '/serve',
    name: 'server',
    component: server
  },
  {
    path: '/car',
    name: 'car',
    component: car
  },
  {
    path: '/architecture',
    name: 'architecture',
    component: architecture
  },
  {
    path: '/financial',
    name: 'financial',
    component: financial
  },
  {
    path: '/retail',
    name: 'retail',
    component: retail
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
