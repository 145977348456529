<template>
    <div class="container_one" style="background-color: #F6F7F8;">
    
        
        <el-row>
        <el-col :span="24"><div class="jcbg">
            <!-- <img src="..\\src\\static\\web\\解决方案-建材行业\\编组 21.png" width="100%"> -->
            <div class="text1" style="text-align: left; padding:10px;width: 400px;">
            <span style="font-size:35px;color:orange;"><b>智慧校园</b><br><br></span>
            <span style="font-size:15px;color:#ffffff;">以内部控制理论为基础，以数字治理为手段，从当前高校内部治理体系建设的实际需求出发，突破传统内部控制体系范畴，从组织战略与核心价值实现全面控制
<br></span>
            </div>
        </div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>解决方案</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">SOLUTION<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        
        <el-row :gutter="100">
        <el-col :span="7"><div>&emsp;</div></el-col>

        <el-col :span="5"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:250px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">01.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">高校数智化“大内控”</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">
以内部控制理论为基础，以数字治理为手段，从当前高校内部治理体系建设的实际需求出发，突破传统内部控制体系范畴，从组织战略与核心价值实现全面控制</span>
                <!-- <ul  style="font-weight: bold;font-size:15px;color:#0a0a0a;">
                    <li>智慧教学环境</li>
                    <li>数字化教学环境</li>
                    <li>智慧教学管理</li>
                </ul> -->
            </div>
            </div></el-col>
        <el-col :span="5"> <div style="background-color: #ffffff;text-align: left;200px;border-radius: 10px;width:100%;height:250px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">02.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">本研一体化人才培养</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">满足师生服务一体化的服务要求，并充分尊重本研业务各自特点，建立覆盖教学运行、科研活动、实践环节、资助体系的全方位一体化支撑，实现本硕博贯通培养</span>
                <!-- <ul  style="font-weight: bold;font-size:15px;color:#0a0a0a;">
                    <li>科研管理服务信息化</li>
                    <li>科研资源共享服务平台</li>
                </ul> -->
            </div>
            </div></el-col>
       


        <el-col :span="7"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="100">
        <el-col :span="7"><div>&emsp;</div></el-col>
        
        <el-col :span="5"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:250px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">03.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">校园一通行</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">通过与各类线上业务应用及线下设备的打通，并在门禁通行、请假出校、校园迎新、健康码检查等多场景下使用，在保障信息安全的基础上，提升服务的便捷性，真正实现“校园一通行”</span>
                <!-- <ul  style="font-weight: bold;font-size:15px;color:#0a0a0a;">
                    <li>应用支撑平台</li>
                    <li>一网通办平台</li>
                    <li>基础应用服务</li>
                    <li>校园信息化</li>
                </ul> -->
            </div>
            </div></el-col>
        <el-col :span="5"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:250px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;">   <span style="font-weight: bold;font-size:25px;color:#f78503;">04.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">后勤综合管理平台</span><br><br></div>
             
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">全面覆盖学校后勤部门工作场景，构建专属校园后勤平台，学校可根据实际需求选择对应解决方案</span>
                <!-- <ul  style="font-weight: bold;font-size:15px;color:#0a0a0a;">
                    <li>智慧图书馆</li>
                    <li>校园卡系统</li>
                    <li>数字化场馆</li>
                    <li>智慧安防</li>
                    <li>智慧后勤</li>
                    <li>智慧园区</li>
                </ul> -->
            </div>
            </div></el-col>
       

        <el-col :span="7"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>产品优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">PRODUCT SUPERIORITY<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>



        <el-row :gutter="80">
        <el-col :span="6"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            
               <img src="..\\src\\static\\web\\解决方案-建材行业\\编组 22@2x.png" width="120px" height="120px">
           
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">高效办公</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">多重审核项目真实性</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">为学校各部门、学院、机构、组织构建畅通互动渠道，为组织协同办公、任务协同、高校综合管理提供平台和抓手</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;200px;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
          
               <img src="..\\src\\static\\web\\解决方案-建材行业\\编组 19@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">智慧教学</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">精准分析、明确定位</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">建设数字高校、智慧校园，形成现代化的教育管理与监测体系，推进管理精准化和决策科学化</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
           
               <img src="..\\src\\static\\web\\解决方案-建材行业\\编组 17@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">精细化管理</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">移动端数据接口</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">打造以学校为主导，社会服务资源、家庭积极参与的共教共管共享共赢校园服务新生态</span></div>
                
            </div>
            </div></el-col>


        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>






       
    
    </div>
    </template>
      
    <style>
    .jcbg{
        height: 400px;
        position: relative;
        background-image: url('../static/web/解决方案-汽车行业/编组 21.png');
        background-size: cover;
        background-position: center;
        .text1{
            position: absolute;
            top: 25%;
            left: 25%;
            letter-spacing: 2px;
            line-height: 1.5;
        }
    }
    
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      