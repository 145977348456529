<template>
<div class="container_one">

    
    <el-row>
    <el-col :span="24"><div>
        <img src="..\\src\\static\\web\\首页\\1bf76afd6cd046124f5894742be7184.png" width="100%">
    </div></el-col>
    </el-row><br><br>

    <el-row>
    <el-col :span="2"><div>&emsp;</div></el-col>
    <el-col :span="20">
        <div style="text-align: left; padding:10px">
        <span style="font-size:35px;color:#0e0d0d;"><b>我们的优势</b><br></span>
        <span style="font-size:15px;color:#0e0d0d81;">OUR STRENGTHS<br></span>
        </div>
    </el-col>
    <el-col :span="2"><div>&emsp;</div></el-col>
    </el-row><br><br><br><br>

    <el-row>
    <el-col :span="5"><div>&emsp;</div></el-col>
    <el-col :span="14">
        <div class="container">
        <div @click="num = 1" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\快 速@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 24@2x.png" width="60px">
        </div>
        <div v-if="num == 1" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">针对客户个性化需求<br>,打造专属解决方案</span>
            <span class="one">应对行业角色不同的业务功能需求</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>
        <div @click="num = 2" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\专 业@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 27@2x.png" width="60px">
        </div>
        <div v-if="num == 2" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">我们有多年多领域各种</span>
            <span class="one">产品研发经验</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>
        <div @click="num = 3" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\质 量@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 30@2x.png" width="60px">
        </div>
        <div v-if="num == 3" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">追求精细化的服务<br>,力求每个环节做精做细</span>
            <span class="one">给客户一个完美的用户体验</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>
        <div @click="num = 4" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\保 障@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 34@2x.png" width="60px">
        </div>
        <div v-if="num == 4" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">产品在使用过程中出现问题<br>,快速响应</span>
            <span class="one">沟通测试<br>,为您提供专业迅速的服务</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>

    </div></el-col>
    <el-col :span="5"><div>&emsp;</div></el-col>
    </el-row><br><br><br><br>

    <el-row>
    <el-col :span="24"><div class="div1">
        <div class="tit1">
        <span style="font-size:35px;color:#e0dede;"><b>主营业务</b><br></span>
        <span style="font-size:15px;color:#e0dede;">COMPANY PROFILE<br></span>
        <span style="font-size:14px;color:#e0dede;">我们提供行业领先的全国数字化整体解决方案及应用产品<br></span>
        </div>
        <div class="li1">

        <div class='box' style="float: left;border-radius: 10px;padding:20px">
            <br>
            
            <img src="..\\src\\static\\web\\首页\编组 17.png" width="100px" height="100px">
           
            <div><br>
                <span style="font-weight: bold;font-size:20px;color:#e6e5e4;">智慧城市</span><br><br>
                <div style="text-align: left"><span style="font-size:15px;color:#e6e5e4;letter-spacing: 2px;line-height: 1.5;">利用先进的数字化技术、以最小的资源消耗和环境退化实现最大化的城市经济效率和美好的生活品质</span></div>
            </div>
            

        </div>
        <div class='box' style="float: left;border-radius: 10px;padding:20px">
            <br>
            
            <img src="..\\src\\static\\web\\首页\编组 13.png" width="100px" height="100px">
           
            <div><br>
                <span style="font-weight: bold;font-size:20px;color:#e6e5e4;">智慧校园</span><br><br>
                <div style="text-align: left"><span style="font-size:15px;color:#e6e5e4;letter-spacing: 2px;line-height: 1.5;">以云计算、人工智能、大数据、区块链、5G 等新一代信息技术以
                “绿色、健康、平安校园” 为主线，赋能教育，推动高等教育 “互联网+教育” 和教育数字化转型</span></div>
            </div>
            </div>

        
        <div class='box' style="float: left;border-radius: 10px;padding:20px">
            <br>
            
            <img src="..\\src\\static\\web\\首页\编组 16.png" width="100px" height="100px">
           
            <div><br>
                <span style="font-weight: bold;font-size:20px;color:#e6e5e4;">人才园</span><br><br>
                <div style="text-align: left"><span style="font-size:15px;color:#e6e5e4;letter-spacing: 2px;line-height: 1.5;">打破人才壁垒，实现资源共育、共研、共创、共享</span></div>
                
            </div>
            </div>
        </div>
        
    </div></el-col>
    </el-row><br><br><br><br><br><br><br><br><br><br><br><br>

    <el-row>
    <el-col :span="2"><div>&emsp;</div></el-col>
    <el-col :span="20">
        <div style="text-align: left; padding:10px">
        <span style="font-size:35px;color:#0e0d0d;"><b>公司介绍</b><br></span>
        <span style="font-size:15px;color:#0e0d0d81;">COMPANY PROFILE<br></span>
        </div>
    </el-col>
    <el-col :span="2"><div>&emsp;</div></el-col>
    </el-row><br>

    <el-row>
    <el-col :span="2"><div>&emsp;</div></el-col>
    <el-col :span="20">
        <div style="text-align: left; padding:10px">
        <span style="font-size:15px;color:#0e0d0da2; letter-spacing: 2px;line-height: 1.5;">
            大微天成是一家面向全国的IT咨询、系统集成、产品、解决方案和人工智能服务的高科技公司。公司成立于2019年，总部位于中国北京，其团队核心成员来自北大、人大和中国科学院，在大数据、人工智能、云计算和物联网等方面积累了多项核心技术，业务主要围绕 “政务、产业、民生” 三大核心领域，旨在为智慧城市、数智乡村、智慧校园和企事业单位客户提供完整的 “数智化” 解决方案和流程。大微天成遵循严格的质量和安全标准，实施严密的安全措施，拥有成熟可靠的管理和开发流程，并获得相关资质认证和软件发明专利。公司凭借深厚的行业专长、成熟的行业实践、强大的团队背景、完善的人才管理以及无处不在的创新精神与实践，助力全国客户尽享科技革新所带来的全新体验，为客户持续创造关键价值。

            <br><br><br>
            <el-button style="color: rgb(247, 246, 246);background-color: rgb(243, 132, 52);" @click="scrollToBottom()">了解更多</el-button>
            <br><br>
        </span>
        </div>
    </el-col>
    <el-col :span="2"><div>&emsp;</div></el-col>
    </el-row><br><br><br><br><br><br><br><br><br><br>

</div>
</template>
  
<style>

.div1{
    
    position: relative;
    height: 600px;
    background-image: url('../static/web/首页/编组 23.png');
    background-size: cover;
    background-position: center;
}
.tit1{
    text-align: right;
    position: absolute;
    right: 10%;
    top: 50px;
}
.li1{
    width: 100%;
    position: absolute;
    top: 40%;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    
}

.box {
        /* position: relative; */
        display: inline-block;
        width: 500px;
        height: 250px;
        margin: 0 7%;
        background-color: rgba(70, 30, 30, 0);

    }
/* .box_img{
    position: absolute;
    top: 0px;
} */

.container_one {
  box-sizing: border-box;
  border: none;
}
.one {

width: 20px;

margin: 0 auto;

line-height: 24px;

font-size: 20px;

color: rgb(100, 97, 97);

}



.container {
    display: flex;
    text-align: center;
    
}
.item {
  /* flex: 1; */
  border: 1px solid rgb(241, 236, 236);
  margin-right: 0px;
  line-height: 200px;
  height: 550px;
  width: 19%;
  background-color: rgb(247, 248, 248);
}
.item2 {
  /* flex: 1; */
  padding: 15px;
  border: 1px solid rgb(241, 236, 236);
  margin-right: 0px;
  line-height: 200px;
  height: 550px;
  width: 24%;
  background-color: rgb(247, 248, 248);
}


</style>

<script>
export default {
components: {
},
name: 'HomeView2',
data() {
  return {
    num:1,
  }
},
methods:{
    scrollToBottom() {
        var height = document.documentElement.scrollHeight;
        window.scrollTo(0, height);
    }
   
  }


};
</script>
  