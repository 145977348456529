<template>
  <el-container > 
    <!-- style="min-width: 1500px;" -->
  <el-header height="80px" class="_header" >
    <br>
    <el-row :gutter="20">
        <el-col :span="4"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
        <el-col :span="4"><div class="grid-content bg-purple">
            <img  class="right-img" src="..\\src\\static\\web\\dfbcc45ce986136de1dc05a092ca936.png" style="border-radius: 40px;">
        </div></el-col>
        <el-col :span="4"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple">

            <el-button id='but_1' class="custom-button" round @click="click_but(1)" style="background-color: #050505;border-color: #050505;color: #ffffff;">首页</el-button>
            <el-button id='but_2' class="custom-button" round @click="click_but(2)" style="background-color: #050505;border-color: #050505;color: #ffffff;">人智汇</el-button>
            <el-button id='but_3' class="custom-button" round @click="click_but(3)" style="background-color: #050505;border-color: #050505;color: #ffffff;">定制开发</el-button>
            <el-dropdown @command="click_but">
              <el-button  id='but_4' class="custom-button" round style="background-color: #050505;border-color: #050505;color: #ffffff;" >
                解决方案
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item  command='4'><a @click="click_but(4)">智慧城市</a></el-dropdown-item>
                <el-dropdown-item  command='7' ><a @click="click_but(7)">智慧校园</a></el-dropdown-item>
                <el-dropdown-item  command='8' ><a @click="click_but(8)">数智乡村</a></el-dropdown-item>
                <el-dropdown-item  command='9' ><a @click="click_but(9)">数字化企业</a></el-dropdown-item> -->
                <el-dropdown-item  command='4'>智慧城市</el-dropdown-item>
                <el-dropdown-item  command='7' >智慧校园</el-dropdown-item>
                <el-dropdown-item  command='8' >数智乡村</el-dropdown-item>
                <el-dropdown-item  command='9' >数字化企业</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button  id='but_5' class="custom-button" round @click="click_but(5)" style="background-color: #050505;border-color: #050505;color: #ffffff;">服务</el-button>
            <el-button  id='but_6' class="custom-button" round @click="click_but(6)" style="background-color: #050505;border-color: #050505;color: #ffffff;">关于我们</el-button>

        </div></el-col>
        <!-- <el-col :span="3"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col> -->
    </el-row>
  </el-header>
  <!-- <el-main style="padding:0;"> -->
        
        <home_components v-show="flag == 1"/>
        <RZH_components v-show="flag == 2"/>
        <dingzhi_components v-show="flag == 3"/>
        <car_components v-show="flag == 4"/>
        <server_components v-show="flag == 5"/>
        <my_components v-show="flag == 6"/>
        <architecture_components v-show="flag == 7"/>
        <financial_components v-show="flag == 8"/>
        <retail_components v-show="flag == 9"/>
      
  <!-- </el-main> -->
  <el-footer height="500px" >
    <el-row :gutter="20">
        <el-col :span="2"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
        <el-col :span="10">
                <br>
                <div class="container">
                    <img src="..\\src\\static\\编组 3.png">&emsp;<span style="font-size:15px;color:#fcfafad2;">
                        <b>致大尽微 卓然天成</b>
                    </span><br><br><br><br><br><br>
                </div>
                

            <div class="container">
                <div style="text-align: left; color:#fcfafac5;padding:10px"><br>
            <span style="font-size:20px;color:#fcfafad2;">联系方式<br><br></span>
            <span style="font-size:10px;color:#fcfafad2;">公司电话: 010-6966 6930<br><br></span>
            <span style="font-size:10px;color:#fcfafad2;">公司邮箱: sales@dwtcyl.com<br><br></span>
            <span style="font-size:10px;color:#fcfafad2;">公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206<br><br><br></span>
            <br>
            <!-- <img src="..\\src\\static\\web\\6b00d83be09830f1b9367fce8c3c128.png"><br>
            <span style="font-size:15px;color:#fcfafad2;">微信公众号<br></span> -->
        
            

            </div>
            </div>
        </el-col>
        <el-col :span="10">
            <br>
            <div style="text-align: left; color:#fcfafac5;padding:10px">
                <span style="font-size:25px;color:#fcfafad2;">联系我们<br><br></span>
                <span style="font-size:15px;color:#fcfafad2;">COMPANY PROFILE<br><br><br></span>
                
                <el-form ref="form" :model="form" label-width="200px" class="frame">

                <span style="font-size:10px;color:#fcfafad2;">公司名称<br><br></span>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input><br><br>
                <span style="font-size:10px;color:#fcfafad2;">您的姓名<br><br></span>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input><br><br>
                <span style="font-size:10px;color:#fcfafad2;">您的电子邮箱<br><br></span>
                <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input><br><br><br>
                <el-button plain style="width: 200px;">立 即 提 交</el-button>
                
                </el-form>

            </div>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
    </el-row>
  </el-footer>
</el-container>

</template>
    
    <style>
    ._header{
      transition: background-color 0.5s ease; /* 添加过渡效果 */
      position: fixed;
        top: 0;
        width: 100%;
        background-color: #070707;
        z-index: 9999
        
    }
    ._header.transparent {
        background-color: rgba(7, 7, 7, 0.589); /* 半透明背景色 */
    }


   .custom-button {
    border-color: black; /* 修改边框颜色 */
    background-color: black; /* 修改背景颜色 */
    color:rgb(252, 252, 252);
    }
    .el-button:focus:not([disabled]), .el-button:hover:not([disabled]) {
  background-color: #f5a30a;
  border-color: #f5a30a;
  color:rgb(12, 11, 11);
}
.el-button--primary.is-active,
    .el-button--primary:clicked {
    background: #f1a008;
    border-color: #f1a008;
    color: #fff;
}


     .el-header, .el-footer {
    background-color: #000408;
    color: rgb(252, 252, 252);
    /* text-align: center;
    line-height: 60px; */
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    /* line-height: 200px; */
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    /* line-height: 160px; */
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    /* line-height: 260px; */
  }
  
  .el-container:nth-child(7) .el-aside {
    /* line-height: 320px; */
  }
         
    </style>
    
    <script>

    import home_components from "..\\src\\components\\home.vue";
    import RZH_components from "..\\src\\components\\RZH.vue";
    import dingzhi_components from "..\\src\\components\\dingzhi.vue";
    import car_components from "..\\src\\components\\car.vue";
    import server_components from "..\\src\\components\\server.vue";
    import my_components from "..\\src\\components\\my.vue";
    import architecture_components from "..\\src\\components\\architecture.vue";
    import financial_components from "..\\src\\components\\financial.vue";
    import retail_components from "..\\src\\components\\retail.vue";
      export default {
        components: {
            home_components,
            RZH_components,
            dingzhi_components,
            car_components,
            server_components,
            my_components,
            architecture_components,
            financial_components,
            retail_components
        },
        name: '大微天成',
        data() {
          return {
            flag: 1,

          }
        },
        methods:{
          click_but(a){
            // alert(a)
            this.flag = a
            this.up_color()
            window.scrollTo(0, 0);
            this.$refs.scrollToTop.scrollIntoView({ behavior: 'smooth' });
          },
          up_color(){
    
            var but_1 = document.getElementById('but_1');
            var but_2 = document.getElementById('but_2');
            var but_3 = document.getElementById('but_3');
            var but_4 = document.getElementById('but_4');
            var but_5 = document.getElementById('but_5');
            var but_6 = document.getElementById('but_6');
            
            if (this.flag == 1) {
              but_1.style.backgroundColor  = 'orange'; 
            } else{
              but_1.style.backgroundColor  = 'black'; 
            }
            if (this.flag == 2) {
              but_2.style.backgroundColor = 'orange'; 
            } else{
              but_2.style.backgroundColor = 'black'; 
            }
            if (this.flag == 3) {
              but_3.style.backgroundColor = 'orange'; 
            } else{
              but_3.style.backgroundColor = 'black'; 
            }
            if (this.flag == 5) {
              but_5.style.backgroundColor = 'orange'; 
            } else{
              but_5.style.backgroundColor = 'black'; 
            }
            if (this.flag == 6) {
              but_6.style.backgroundColor = 'orange'; 
            }else{
              but_6.style.backgroundColor = 'black'; 
            }
            if ([4, 7, 8, 9,'4','7','8','9'].includes(this.flag)) {
              but_4.style.backgroundColor = 'orange'; 
            }else{
              but_4.style.backgroundColor = 'black'; 
            }
            

          }
          
          

        },
        mounted:function(){
          this.$refs.scrollToTop;
          this.up_color()

          var header = document.querySelector('._header');
    
          window.addEventListener('scroll', function() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            
            if (scrollTop > 0) {
              header.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'; /* 滚动时设置背景色为完全不透明黑色 */
            } else {
              header.style.backgroundColor = 'rgba(0, 0, 0, 1)'; /* 滚动到顶部时恢复半透明 */
            }
          });
        },
        beforeDestroy() {
          window.removeEventListener('scroll');
        }
      };
    </script>
      
    
     