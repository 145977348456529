<template>
    <div class="container_one" style="background-color: #F6F7F8;">
    
        
        <el-row>
        <el-col :span="24"><div>
            <img src="..\\src\\static\\web\\服务\\e90dbcbb2e62ebc1ef0fa97d88bc1b9.png" width="100%">
        </div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>服务类型</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">SERVICR TYPE<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        
        <el-row :gutter="60">
        <el-col :span="6"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:250px;height:300px;padding:20px">
            <br><br>
            <div style="background-color: #f78503;border-radius: 10px;" width="130px" height="130px">
               <img src="..\\src\\static\\web\\服务\\编组@2x(1).png" width="120px" height="120px">
            </div>
            <div><br>
                <span style="font-size:15px;color:#f78503;">5个</span><span style="font-size:15px;color:#0a0a0a;">服务阶段</span><br><br>
                <div style="text-align: left;"><span style="font-size:12px;color:#0a0a0a;">需求梳理，实行制度，后台部署，使用分析，应用扩展五个阶段更好地帮助企业落地使用，
                    为企业客户打造无缝对接专业服务</span></div>
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;200px;border-radius: 10px;width:250px;height:300px;padding:20px">
            <br><br>
            <div style="background-color: #f78503;border-radius: 10px;" width="130px" height="130px">
               <img src="..\\src\\static\\web\\服务\\编组5@2x.png" width="120px" height="120px"> 
            </div>
            <div><br>
                <span style="font-size:15px;color:#f78503;">1V1</span><span style="font-size:15px;color:#0a0a0a;">专属客服</span><br><br>
                <div style="text-align: left;"><span style="font-size:12px;color:#0a0a0a;">有问题，找专属客服经理，从产品咨询、营销推广、
                    产品培训、系统配置、操作指导到意见反馈提供360°全方位贴心服务</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:250px;height:300px;padding:20px">
            <br><br>
            <div style="background-color: #f78503;border-radius: 10px;" width="130px" height="130px">
               <img src="..\\src\\static\\web\\服务\\编组7@2x.png" width="120px" height="120px"> 
            </div>
            <div><br>
                <span style="font-size:15px;color:#f78503;">云端</span><span style="font-size:15px;color:#0a0a0a;">架构，</span>
                <span style="font-size:15px;color:#0a0a0a;">多重</span><span style="font-size:15px;color:#f78503;">加密</span><br><br>
                <div style="text-align: left;"><span style="font-size:12px;color:#0a0a0a;">云端架构，多重加密，安全可靠大幅度降低运维成本，全方位保护客户的数据安全，高效防止企业重要数据泄漏</span></div>
                
            </div>
            </div></el-col>


        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>价值交付</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">VALUE DELIVERY<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>


        <el-row>
        <el-col :span="6"><div>&emsp;</div></el-col>
        <el-col :span="12">
            <div style="text-align: center; padding:10px">
                <img src="..\\src\\static\\web\\服务\\58f1d3437ff4e54841f2e11c9bf5219.png"> 
            </div>
        </el-col>
        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>





       
    
    </div>
    </template>
      
    <style>
    
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      