<template>
  <el-container>
  <el-header height="80px">
    <br>
    <el-row :gutter="20">
        <el-col :span="4"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
        <el-col :span="4"><div class="grid-content bg-purple">
            <img  class="right-img" src="..\\src\\static\\web\\dfbcc45ce986136de1dc05a092ca936.png">
        </div></el-col>
        <el-col :span="4"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
        <el-col :span="9"><div class="grid-content bg-purple">

            <el-button class="custom-button" round @click="flag = 1">首页</el-button>
            <el-button class="custom-button" round @click="flag = 2">人智汇</el-button>
            <el-button class="custom-button" round @click="flag = 3">定制开发</el-button>
            <el-button class="custom-button" round @click="flag = 4">解决方案</el-button>
            <el-button class="custom-button" round @click="flag = 5">服务</el-button>
            <el-button class="custom-button" round @click="flag = 6">关于我们</el-button>

        </div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
    </el-row>
  </el-header>
  <el-main style="padding:0;">
    <div class="container_one">

    
<el-row>
<el-col :span="24"><div>
    <img src="..\\src\\static\\web\\首页\\1bf76afd6cd046124f5894742be7184.png" width="100%">
</div></el-col>
</el-row><br><br>

<el-row>
<el-col :span="2"><div>&emsp;</div></el-col>
<el-col :span="20">
    <div style="text-align: left; padding:10px">
    <span style="font-size:35px;color:#0e0d0d;"><b>我们的优势</b><br></span>
    <span style="font-size:15px;color:#0e0d0d81;">OUR STRENGTHS<br></span>
    </div>
</el-col>
<el-col :span="2"><div>&emsp;</div></el-col>
</el-row><br><br><br><br>

<el-row>
<el-col :span="4"><div>&emsp;</div></el-col>
<el-col :span="16"><div class="container">
    <div @click="num = 1" class="item">
        <br>
        <img src="..\\src\\static\\web\\首页\\快 速@2x.png" width="40px"><br>
        <img src="..\\src\\static\\web\\首页\\编组 24@2x.png" width="60px">
    </div>
    <div v-if="num == 1" class="item2">
        <div class="container">
        <br><br><br>
        <span class="one">针对客户个性化需求打造专属解决方案,</span>
        <span class="one">应对行业,角色不同的业务功能需求</span>

        <div class="image-container">
            <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
        </div>
        </div>
    </div>
    <div @click="num = 2" class="item">
        <br>
        <img src="..\\src\\static\\web\\首页\\专 业@2x.png" width="40px"><br>
        <img src="..\\src\\static\\web\\首页\\编组 27@2x.png" width="60px">
    </div>
    <div v-if="num == 2" class="item2">
        <div class="container">
        <br><br><br>
        <span class="one">针对客户个性化需求打造专属解决方案,</span>
        <span class="one">应对行业,角色不同的业务功能需求</span>

        <div class="image-container">
            <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
        </div>
        </div>
    </div>
    <div @click="num = 3" class="item">
        <br>
        <img src="..\\src\\static\\web\\首页\\质 量@2x.png" width="40px"><br>
        <img src="..\\src\\static\\web\\首页\\编组 30@2x.png" width="60px">
    </div>
    <div v-if="num == 3" class="item2">
        <div class="container">
        <br><br><br>
        <span class="one">针对客户个性化需求打造专属解决方案,</span>
        <span class="one">应对行业,角色不同的业务功能需求</span>

        <div class="image-container">
            <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
        </div>
        </div>
    </div>
    <div @click="num = 4" class="item">
        <br>
        <img src="..\\src\\static\\web\\首页\\保 障@2x.png" width="40px"><br>
        <img src="..\\src\\static\\web\\首页\\编组 34@2x.png" width="60px">
    </div>
    <div v-if="num == 4" class="item2">
        <div class="container">
        <br><br><br>
        <span class="one">针对客户个性化需求打造专属解决方案,</span>
        <span class="one">应对行业,角色不同的业务功能需求</span>

        <div class="image-container">
            <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
        </div>
        </div>
    </div>

</div></el-col>
<el-col :span="4"><div>&emsp;</div></el-col>
</el-row><br><br><br><br>

<el-row>
<el-col :span="24"><div>
    <img src="..\\src\\static\\web\\首页\\7a34ff614c4bcee50e391bb854464f5.png" width="100%">
</div></el-col>
</el-row><br><br>

<el-row>
<el-col :span="2"><div>&emsp;</div></el-col>
<el-col :span="20">
    <div style="text-align: left; padding:10px">
    <span style="font-size:35px;color:#0e0d0d;"><b>公司介绍</b><br></span>
    <span style="font-size:15px;color:#0e0d0d81;">COMPANY PROFILE<br></span>
    </div>
</el-col>
<el-col :span="2"><div>&emsp;</div></el-col>
</el-row><br>

<el-row>
<el-col :span="2"><div>&emsp;</div></el-col>
<el-col :span="20">
    <div style="text-align: left; padding:10px">
    <span style="font-size:15px;color:#0e0d0da2;">
        大微天成是一家面向全国的IT咨询、产品、解决方案与服务的提供商
        公司成立于2019年，总部位于中国北京，依托自身强大的研发与创新能力，广泛采用基于大数据、人工智能、
        云计算和物联网等新兴技术，为教育、金融、高科技、互联网等行业客户提供丰富的解决方案及产品。大微天
        成遵循严格的质量和安全标准，实施严密的安全措施，拥有成熟可靠的管理和开发流程，并获得CMMI 5、ISO
        9001、ISO 14001等一系列资质认证。公司凭借深厚的行业专长和成熟的行业实践、中国科学院和中国科学院
        大学的精英团队和完善的人才管理、完备的全球化交付与服务网络、以及无处不在的创新精神与实践，助力全
        球客户尽享科技革新所带来的卓越运营，为客户持续创造关键价值。
        <br><br><br>
        <el-button style="color: rgb(247, 246, 246);background-color: rgb(243, 132, 52);">了解更多</el-button>
        <br><br>
    </span>
    </div>
</el-col>
<el-col :span="2"><div>&emsp;</div></el-col>
</el-row><br><br>

</div>
  </el-main>
  <el-footer height="500px">
    <el-row :gutter="20">
        <el-col :span="2"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
        <el-col :span="10">
                <br>
                <div class="container">
                    <img src="..\\src\\static\\编组 3.png">&emsp;<span style="font-size:15px;color:#fcfafad2;">
                        <b>尽大至微，卓然天成</b>
                    </span><br><br><br><br><br><br>
                </div>
                

            <div class="container">
                <div style="text-align: left; color:#fcfafac5;padding:10px">
            <span style="font-size:20px;color:#fcfafad2;">联系方式<br><br></span>
            <span style="font-size:10px;color:#fcfafad2;">公司电话: 010-6966 6930<br><br></span>
            <span style="font-size:10px;color:#fcfafad2;">公司邮箱: sales@dwtcyl.com<br><br></span>
            <span style="font-size:10px;color:#fcfafad2;">公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206<br><br><br></span>
        
            <img src="..\\src\\static\\web\\6b00d83be09830f1b9367fce8c3c128.png"><br>
            <span style="font-size:15px;color:#fcfafad2;">微信公众号<br></span>
        
            

            </div>
            </div>
        </el-col>
        <el-col :span="10">
            <br>
            <div style="text-align: left; color:#fcfafac5;padding:10px">
                <span style="font-size:25px;color:#fcfafad2;">联系我们<br><br></span>
                <span style="font-size:15px;color:#fcfafad2;">COMPANY PROFILE<br><br><br></span>
                
                <el-form ref="form" :model="form" label-width="200px" class="frame">

                <span style="font-size:10px;color:#fcfafad2;">公司名称<br><br></span>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input><br><br>
                <span style="font-size:10px;color:#fcfafad2;">您的姓名<br><br></span>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input><br><br>
                <span style="font-size:10px;color:#fcfafad2;">您的电子邮箱<br><br></span>
                <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input><br><br>
                <el-button plain>立即提交</el-button>
                
                </el-form>

            </div>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"><span>&emsp;</span></div></el-col>
    </el-row>
  </el-footer>
</el-container>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container_one {
  box-sizing: border-box;
  border: none;
}
.one {

width: 20px;

margin: 0 auto;

line-height: 24px;

font-size: 20px;

color: rgb(100, 97, 97);

}



.container {
    display: flex;
    text-align: center;
    
}
/* .item {
  flex: 1;
  border: 1px solid rgb(241, 236, 236);
  margin-right: 0px;
  line-height: 200px;
  height: 700px;
  width: 100px;
  background-color: rgb(247, 248, 248);
}
.item2 {
  flex: 1;
  border: 1px solid rgb(241, 236, 236);
  margin-right: 0px;
  line-height: 200px;
  height: 700px;
  width: 300px;
  background-color: rgb(247, 248, 248);
} */

.custom-button {
    border-color: black; /* 修改边框颜色 */
    background-color: black; /* 修改背景颜色 */
    color:rgb(252, 252, 252);
    }
    .el-button:focus:not([disabled]), .el-button:hover:not([disabled]) {
  background-color: #f5a30a;
  border-color: #f5a30a;
  color:rgb(12, 11, 11);
}



     .el-header, .el-footer {
    background-color: #000408;
    color: rgb(252, 252, 252);
    /* text-align: center;
    line-height: 60px; */
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    /* line-height: 200px; */
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    /* line-height: 160px; */
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    /* line-height: 260px; */
  }
  
  .el-container:nth-child(7) .el-aside {
    /* line-height: 320px; */
  }


</style>
