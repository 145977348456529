<template>
    <el-container style="background-color:#ffffff">
    <el-header style="background-color:#f3f0f0d3;height: 80px;">
        <br>
        <el-col :span="15"><div>
            <el-input
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="input2">
            </el-input>
        </div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message-solid" style="color: black;"></i></div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message" style="color: black;"></i></div></el-col>
        <el-col :span="3">
            
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="change-icon"><i class="el-icon-more"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus"><a href="/home">首页</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/rzh">人智汇</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dangjian">党建</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/my">关于我们</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dingzhi">定制开发</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/serve">服务</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/car">解决方案-汽车行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/architecture">解决方案-建筑行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/financial">解决方案-金融行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/retail">解决方案-零售行业</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            
        </el-col>
    </el-header>
    <el-main style="background-color: rgb(250, 248, 248);padding:0;">
        <el-row :gutter="20">
        <el-col :span="24"><div class="grid-content bg-purple"><img src="..\\src\\static\\人智汇\\rzh.png" width="100%" height="100%"></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="15">
                <div style="text-align: left;">
                    <h1><font size="10">人智汇</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">FUNCTION ANALYSIS</font>
                </div>
            </el-col>
            <el-col :span="8"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">
                        &nbsp;&nbsp;&nbsp;&nbsp;人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，
                        为企业打造覆盖核心人力事务、员工服务、人才运营、数智决策等模块的一站式服务，推动组织数字
                        化变革与战略目标的达成。帮助企业建立完善的人力资源体系流程，达到降本增效的目的。
                    </h4>
                    
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>


        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 20px;">
                    
                        
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                        <img src="..\\src\\static\\人智汇\\编组 26备份@3x.png" class="left-img">
                        <span>资源规划</span>
                        <img src="..\\src\\static\\人智汇\\编组 13@3x.png"  class="right-img" height="60px">
                        
                    </div><hr>
                    
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链。
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 20px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\人智汇\\编组 24备份@3x.png" class="left-img">
                            <span>绩效管理</span>
                            <img src="..\\src\\static\\人智汇\\编组 22@3x.png"  class="right-img" height="60px">
                            
                        </div><hr>
                    
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链。
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 20px;">
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                         <img src="..\\src\\static\\人智汇\\编组 23备份@3x.png" class="left-img">
                            <span>员工关系</span>
                        <img src="..\\src\\static\\人智汇\\编组 23@3x.png"  class="right-img" height="60px">
                    </div>
                    
                   
                    
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链。
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 20px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                         <img src="..\\src\\static\\人智汇\\编组 28@3x.png" class="left-img">
                            <span>薪酬福利</span>
                        <img src="..\\src\\static\\人智汇\\编组 24@3x.png"  class="right-img" height="60px">
                    </div>
                    
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链。
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 20px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                         <img src="..\\src\\static\\人智汇\\编组 32备份@3x.png" class="left-img">
                            <span>招聘设置</span>
                        <img src="..\\src\\static\\人智汇\\编组 25@3x.png"  class="right-img" height="60px">
                    </div>
                    
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链。
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>
        
        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 20px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                         <img src="..\\src\\static\\人智汇\\编组 44备份@3x.png" class="left-img">
                            <span>培训开发</span>
                        <img src="..\\src\\static\\人智汇\\编组 27@3x.png"  class="right-img" height="60px">
                    </div>
                    

                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，
                    人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链。
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="18">
                <div style="text-align: left;">
                    <h1><font size="10">产品优势</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">PRODUCT SUPERIORITY</font>
                </div>
            </el-col>
            <el-col :span="5"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="10">
                <div style="border: 1px solid orange; border-radius: 10px; padding: 10px;width: 165px;height:165px;">
                    <img src="..\\src\\static\\人智汇\\Innovation _Flatline 2备份@3x.png" width="165px">
                </div>
                
            </el-col>
            <el-col :span="10">
                <h4>技术领先 独家专利</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    融合中科院前沿AI技术，智能化提取简历信息，多角度解析人物画像，精准人岗匹配
                </font>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="10">
                <h4>首款全模块系统</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    融合中科院前沿AI技术，智能化提取简历信息，多角度解析人物画像，精准人岗匹配
                </font>
            </el-col>
            <el-col :span="10">
                <div style="border: 1px solid orange; border-radius: 10px; padding: 10px;width: 165px;height:165px;">
                    <img src="..\\src\\static\\人智汇\\Data processing _Flatline 3备份@3x.png" width="165px">
                </div>
                
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="10">
                <div style="border: 1px solid orange; border-radius: 10px; padding: 10px;width: 165px;height:165px;">
                    <img src="..\\src\\static\\人智汇\\Finance app_Flatline 2备份@3x.png" width="165px">
                </div>
                
            </el-col>
            <el-col :span="10">
                <h4>智能分析 数据洞见</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    融合中科院前沿AI技术，智能化提取简历信息，多角度解析人物画像，精准人岗匹配
                </font>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="10">
                <h4>贴合业务 便捷协同</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    融合中科院前沿AI技术，智能化提取简历信息，多角度解析人物画像，精准人岗匹配
                </font>
            </el-col>
            <el-col :span="10">
                <div style="border: 1px solid orange; border-radius: 10px; padding: 10px;width: 165px;height:165px;">
                    <img src="..\\src\\static\\人智汇\\User interface_Flatline 4备份@3x.png" width="165px">
                </div>
                
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="10">
                <div style="border: 1px solid orange; border-radius: 10px; padding: 10px;width: 165px;height:165px;">
                    <img src="..\\src\\static\\人智汇\\Information flow_Flatline 2备份@3x.png" width="165px">
                </div>
                
            </el-col>
            <el-col :span="10">
                <h4>ToC交互设计</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    融合中科院前沿AI技术，智能化提取简历信息，多角度解析人物画像，精准人岗匹配
                </font>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">核心价值</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">FUNCTION ANALYSIS</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 224px;">
                    
                    <span><img src="..\\src\\static\\人智汇\\编组 18@3x.png" ></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">新管控</span>
                    支持多组织架构、业务协作、政策共享与隔离、管控权限设置
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 224px;">
                    
                    <span><img src="..\\src\\static\\人智汇\\编组 29@3x.png" ></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">新共享</span>
                    基于HR三支柱运营模式的一体化人力共享服务平台
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 224px;">
                    
                    <span><img src="..\\src\\static\\人智汇\\编组 30@3x.png" ></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">新绩效</span>
                    战略解码、逐级分解指标、实时反馈、激励赋能、智能分析
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 224px;">
                    
                    <span><img src="..\\src\\static\\人智汇\\编组 33@3x.png" ></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">新智能</span>
                    全新的人工智能技术融入到HR管理中
                    
            </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">联系我们</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CONTACT US</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">公司名称</h4>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的姓名</h4>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的电话</h4>
                <el-input v-model="input" placeholder="请输入联系电话"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">您的电子邮箱</h4>
                    <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>
        
        
        
        <!-- <div style="background-color:#0e0c0ce1;">
            <div class="container">
                &nbsp;&nbsp;<img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&nbsp;尽大至微，卓然天成</h3>
            </div>
            <hr>
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

</div> -->

    </el-main>
    <el-footer style="height: 300px;background-color: #2B2C31">
        <div >
            <div class="container">
                <img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&emsp;&emsp;尽大至微，卓然天成</h3>
        </div>
            <hr size="1">
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

        </div>
    </el-footer>
    </el-container>
</template>
  
  <script>

  export default {
    data() {
      return {

      }
    },
    

  }
  </script>

  <style>
.change-icon{
    font-size:35px;
}

.centered-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center; /* 将内容垂直置中 */
  }

  .box {
    width: 100px;
    height: 100px;
    /* background-color: #EAE5F6; */
    }
.container {
display: flex;
align-items: center; /* 垂直居中对齐 */
}
img {
    max-width: 100%;
    max-height: 100%;
  }

a {
    text-decoration: none;
    color: #000;
  }
  .left-img {
    float: left;
    margin-right: 20px;
    /* position: absolute; */
    /* bottom: 10;  */
    /* left: 1; */
  }
  .right-img {
    float: right;
    margin-left: 20px;
    /* position: absolute; */
    /* bottom: 10; */
    /* right: 0; */
  }
  .box {
    display: flex;
    align-items: flex-end;
  }
  .container {
    text-align: center;
  }
  </style>
  