<template>
    <el-container style="background-color:#f3f0f0d3">
    <el-header style="background-color:#f3f0f0d3;height: 80px;">
        <br>
        <el-col :span="15"><div>
            <el-input
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="input2">
            </el-input>
        </div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message-solid" style="color: black;"></i></div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message" style="color: black;"></i></div></el-col>
        <el-col :span="3">
            
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="change-icon"><i class="el-icon-more"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus"><a href="/home">首页</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/rzh">人智汇</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dangjian">党建</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/my">关于我们</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dingzhi">定制开发</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/serve">服务</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/car">解决方案-汽车行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/architecture">解决方案-建筑行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/financial">解决方案-金融行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/retail">解决方案-零售行业</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            
        </el-col>
    </el-header>
    <el-main style="background-color: rgb(250, 248, 248);padding:0;">
        <el-row :gutter="20">
        <el-col :span="24"><div class="grid-content bg-purple background-div"><img src="..\\src\\static\\服务\\734ad1eb8be378734bb5678f1e0f00c.png" width="100%" height="100%"></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">服务类型</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">SERVICR TYPE</font>
                </div>
            </el-col>
            <el-col :span="8"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24">
                <div>
                    
                    <img src="..\\src\\static\\服务\\编组 4备份@3x.png">
                    
                </div>
            </el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="centered-container" >
                   <h3 style="text-align: left;"><span style="color: rgb(224, 131, 24);">五个</span>服务阶段</h3>
                   <h4 style="text-align: left;">需求梳理，实行制度，后台部署使用分析，应用扩展五个阶段更好地帮助企业落地使用，
                    为企业客户打造无缝对接专业服务</h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24">
                <div>
                    
                    <img src="..\\src\\static\\服务\\Data storage_Monochromatic 2备份 2@3x.png">
                    
                </div>
            </el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="centered-container" >
                   <h3 style="text-align: left;"><span style="color: rgb(224, 131, 24);">1V1专属</span>客服</h3>
                   <h4 style="text-align: left;">有问题，找专属客服经理，从产品咨询、营销推广、
                    产品培训、系统配置、操作指导到意见反馈 提供360°全方位贴心服务</h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24">
                <div>
                    
                    <img src="..\\src\\static\\服务\\Data storage_Monochromatic 2备份 2@3x.png">
                    
                </div>
            </el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="centered-container" >
                   <h3 style="text-align: left;"><span style="color: rgb(224, 131, 24);">云端</span>架构， 多重<span style="color: rgb(224, 131, 24);">加密</span></h3>
                   <h4 style="text-align: left;">云端架构，多重加密，安全可靠 大幅度降低运维成本，
                    全方位保护客户的数据安全，高效防止企 业重要数据泄漏</h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">价值交付</font></h1>
                    <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">VALUE DELIVERY</font>
                </div>
            </el-col>
            <el-col :span="8"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="11">
                <div >
                    <img src="..\\src\\static\\服务\\sop.png" width="100%" height="100%">
                </div>
                
            </el-col>
            <el-col :span="11">
                <h4>基础管理改善</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    从基础出发，资源(人财物)编码、名称、规格进行规范和统一，巩固信息化基础。
                </font>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="11">
                <h4>ERP作业指导书<br>SOP设计</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    固化企业业务流程，结合系统以SOP固化流程实现管理精进，降低企业培训成本
                </font>
            </el-col>
            <el-col :span="11">
                <div >
                    <img src="..\\src\\static\\服务\\Rectangle 21备份 3@3x.png" width="100%" height="100%">
                </div>
                
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="11">
                <div >
                    <img src="..\\src\\static\\服务\\位图@3x.png" width="100%" height="100%">
                </div>
                
            </el-col>
            <el-col :span="11">
                <h4>制定上线验收标准和交付标准</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    产品方案交付标准双方制定，上线成功有力承诺，指标化价值交付拷贝
                </font>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="11">
                <h4>搭建各阶层管理手机报表平台</h4>
                <font size="4" style="color: rgba(9, 10, 12, 0.527); text-align: left;">
                    搭建各阶层管理层营运监控报表系统，实现透明化管理
                </font>
            </el-col>
            <el-col :span="11">
                <div >
                    <img src="..\\src\\static\\服务\\bbpt.png" width="100%" height="100%">
                </div>
                
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">联系我们</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CONTACT US</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">公司名称</h4>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的姓名</h4>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的电话</h4>
                <el-input v-model="input" placeholder="请输入联系电话"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">您的电子邮箱</h4>
                    <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>
        
        
        
        <!-- <div style="background-color:#0e0c0ce1;">
            <div class="container">
                &nbsp;&nbsp;<img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&nbsp;尽大至微，卓然天成</h3>
            </div>
            <hr>
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

</div> -->

    </el-main>
    <el-footer style="height: 300px;background-color: #2B2C31">
        <div >
            <div class="container">
                <img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&emsp;&emsp;尽大至微，卓然天成</h3>
        </div>
            <hr size="1">
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

        </div>
    </el-footer>
    </el-container>
</template>
  
  <script>

  export default {
    data() {
      return {

      }
    },
    

  }
  </script>

  <style>
.change-icon{
    font-size:35px;
}

.centered-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center; /* 将内容垂直置中 */
  }

  .box {
    width: 100px;
    height: 100px;
    /* background-color: #EAE5F6; */
    }
.container {
display: flex;
align-items: center; /* 垂直居中对齐 */
}
a {
    text-decoration: none;
    color: #000;
  }
  /* .background-div { */
    /* width: 300px; */
    /* height: 200px; */
    /* background-image: url('..\\src\\static\\服务\\编组 21备份@3x.png'); 设置背景图像的 URL */
    /* background-size: cover; 调整图像大小以填满整个背景 */
    /* background-position: center; 将图像置于背景中心位置 */
    /* background-repeat: no-repeat; 禁止图像在背景中重复 */
  /* } */

  </style>
  