<template>
    <el-container style="background-color:#f3f0f0d3">
    <el-header style="background-color:#f3f0f0d3;height: 80px;">
        <br>
        <el-col :span="15"><div>
            <el-input
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="input2">
            </el-input>
        </div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message-solid" style="color: black;"></i></div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message" style="color: black;"></i></div></el-col>
        <el-col :span="3">
            
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="change-icon"><i class="el-icon-more"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus"><a href="/home">首页</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/rzh">人智汇</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dangjian">党建</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/my">关于我们</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dingzhi">定制开发</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/serve">服务</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/car">解决方案-汽车行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/architecture">解决方案-建筑行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/financial">解决方案-金融行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/retail">解决方案-零售行业</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            
        </el-col>
    </el-header>
    <el-main style="background-color: rgb(250, 248, 248);padding:0;">
        <el-row :gutter="20">
        <el-col :span="24"><div class="grid-content bg-purple"><img src="..\\src\\static\\汽车\\09e8cd9569cadd711626c2f41a24aa0.png" width="100%" height="100%"></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">前景分析</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">PROSPECT ANALYSIS</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
   
                    <div class="parent">
                        
                    <div class="child1">
                        
                    </div>
                    <div class="child2">
                        <img src="..\\src\\static\\汽车\\编组 13@3x.png"  class="right-img2">
                        <div style="padding: 10px;text-align: left;">
                            <h2 style="text-align: left;">客户难画像</h2>
                            <span>
                            客户量大且类型多样化难以形成统一的分
                            类、分级标准，很难构建客户的 完整画像
                            </span> 
                        </div>
                        
                    </div> 
                        
                    
                    </div>
                
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">

                    <div class="parent1">
                    <div class="child3"></div>
                    <div class="child4">
                        <img src="..\\src\\static\\汽车\\编组 22备份@3x.png"  class="left-img2">
                        <div style="padding: 10px;text-align: left;">
                            <h2 style="text-align: right;">销售过程无规范</h2>
                            <span>
                                销售周期长切复杂，销售过程不透明 主管无
                                法即使了解团队的工作情况并 切无法即使解
                                决问题
                            </span> 
                        </div>
                    </div> 
                    </div>
                
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

                <div class="parent">
                    
                <div class="child1">
                    
                </div>
                <div class="child2">
                    <img src="..\\src\\static\\汽车\\编组 23备份 2@3x.png"  class="right-img2">
                    <div style="padding: 10px;text-align: left;">
                        <h2 style="text-align: left;">团队协作效率低</h2>
                        <span>
                            针对大客户的销售以定制为主，对团队协作的
                            要求非常高，但各个部门协 作配合的能力有限
                        </span> 
                    </div>    
                </div>     
                </div>
        </el-col>
        <el-col :span="1">&emsp;</el-col>
    </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">

           <div class="parent1">
           <div class="child3"></div>
           <div class="child4">
               <img src="..\\src\\static\\汽车\\编组 24备份 2@3x.png"  class="left-img2">
               <div style="padding: 10px;text-align: left;">
                   <h2 style="text-align: right;">债权管理风险高</h2>
                   <span>
                    汽车价值高，销售多样化，通常为信 用销售
                    为主，需要有强大的债权管理 能力来降低销
                    售风险
                   </span> 
               </div>
           </div> 
           </div>
       
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>
        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

                <div class="parent">
                    
                <div class="child1">
                    
                </div>
                <div class="child2">
                    <img src="..\\src\\static\\汽车\\编组 25备份@3x.png"  class="right-img2">
                    <div style="padding: 10px;text-align: left;">
                        <h2 style="text-align: left;">渠道把控能力弱</h2>
                        <span>
                            大部分汽车企业销售模式为经销+直销，而对
                            渠道的把控能力直接决定市 场占有率
                        </span> 
                    </div>    
                </div>     
                </div>
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
        <el-col :span="22">

        <div class="parent1">
        <div class="child3"></div>
        <div class="child4">
            <img src="..\\src\\static\\汽车\\编组 27备份@3x.png"  class="left-img2">
            <div style="padding: 10px;text-align: left;">
                <h2 style="text-align: right;">客户满意度难提升</h2>
                <span>
                    由于行业特点，汽车销售之后，很难 找到触
                    点提升客服的满意度，客户问 题很难快速被
                    响应
                </span> 
            </div>
        </div> 
        </div>

        </el-col>
        <el-col :span="1">&emsp;</el-col>
        </el-row><br>



        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">解决方案</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">SOLUTION</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div style="border: 1px solid rgba(15, 15, 15, 0.884); border-radius: 10px; padding: 30px;">
                    <img src="..\\src\\static\\汽车\\编组 23备份@3x.png">
                    <h3 style="text-align: left;color: rgb(243, 169, 9);">全渠道链接</h3>
                    <h3 style="text-align: left;color: rgba(12, 12, 10, 0.945);">多方面提升客户体验</h3>
                    <h4 style="text-align: left;color: rgba(39, 37, 21, 0.651);">
                        需求梳理，实行制度，后台部署使用分析，应用扩展五个阶段更好地帮助企业落地使用，为企业客户打造无缝对接专业服务
                    </h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div style="border: 1px solid rgba(15, 15, 15, 0.884); border-radius: 10px; padding: 30px;">
                    <img src="..\\src\\static\\汽车\\编组 20备份@3x.png" >
                    <h3 style="text-align: left;color: rgb(243, 169, 9);">客户360°画像</h3>
                    <h3 style="text-align: left;color: rgba(12, 12, 10, 0.945);">精准分析、明确定位</h3>
                    <h4 style="text-align: left;color: rgba(39, 37, 21, 0.651);">
                        客户标签体系，360”了解客户 基础，会员全生命周期管理持续 挖掘会员价值精准预知消费者需求，货品推荐智能化
                    </h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div style="border: 1px solid rgba(15, 15, 15, 0.884); border-radius: 10px; padding: 30px;">
                    <img src="..\\src\\static\\汽车\\编组 18备份@3x.png" >
                    <h3 style="text-align: left;color: rgb(243, 169, 9);">优化流程</h3>
                    <h3 style="text-align: left;color: rgba(12, 12, 10, 0.945);">提升服务运营效率</h3>
                    <h4 style="text-align: left;color: rgba(39, 37, 21, 0.651);">
                        优化MTL、LTC、ITR全流程， 实现流程规范化;实现线上审批 同时集成ERP等周边系统提升 销售、服务内部运营效率
                    </h4>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

       
        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">联系我们</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CONTACT US</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">公司名称</h4>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的姓名</h4>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的电话</h4>
                <el-input v-model="input" placeholder="请输入联系电话"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">您的电子邮箱</h4>
                    <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>
        
        
        
        <!-- <div style="background-color:#0e0c0ce1;">
            <div class="container">
                &nbsp;&nbsp;<img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&nbsp;尽大至微，卓然天成</h3>
            </div>
            <hr>
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

</div> -->

    </el-main>
    <el-footer style="height: 300px;background-color: #2B2C31">
        <div >
            <div class="container">
                <img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&emsp;&emsp;尽大至微，卓然天成</h3>
        </div>
            <hr size="1">
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

        </div>
    </el-footer>
    </el-container>
</template>
  
  <script>

  export default {
    data() {
      return {

      }
    },
    

  }
  </script>

  <style>
.change-icon{
    font-size:35px;
}

.centered-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center; /* 将内容垂直置中 */
  }

  .box {
    width: 100px;
    height: 100px;
    /* background-color: #EAE5F6; */
    }
.container {
display: flex;
align-items: center; /* 垂直居中对齐 */
}
a {
    text-decoration: none;
    color: #000;
  }
  .left-img {
    float: left;
    margin-right: 20px;
    /* position: absolute; */
    /* bottom: 10;  */
    /* left: 1; */
  }
  .right-img {
    float: right;
    margin-left: 20px;
    /* position: absolute; */
    /* bottom: 10; */
    /* right: 0; */
  }
  .left-img2 {
    /* float: left;
    margin-right: 20px;
    margin-top: 0px; */
    position: absolute;
    top: -30px; 
    left: 45px;
    width: 40px;
    height: 60px;
  }
  .right-img2 {
    /* float: right;
    margin-left: 20px;
    margin-top: 0px; */
    /* position: absolute; */
    position: absolute;
    top: -30px;
    right: 45px;
    width: 40px;
    height: 60px;
  }

  .parent {
  position: relative;
  height: 250px;
  width: 100%;
  .child1 {
    border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 250px;
  background-color: #e9e8e68c;
  z-index: 1;
  .image{
    top: 0;
    right: 0;
  }
}
.child2 {
  border-radius: 20px;
  position: absolute;
  top: 25px;
  left: 40px;
  width: 90%;
  height: 200px;
  background-color: rgb(255, 254, 254);
  border: 1px solid rgb(255, 166, 0);
  z-index: 2;
}
}





.parent1 {
  position: relative;
  height: 250px;
  width: 100%;
  .child3 {
    border-radius: 20px;
  position: absolute;
  top: 0;
  left: 40px;
  width: 90%;
  height: 250px;
  background-color: #e9e8e68c;
  z-index: 1;
};
.child4 {
    border-radius: 20px;
  position: absolute;
  top: 25px;
  left: 0;
  width: 90%;
  height: 200px;
  background-color: rgb(255, 254, 254);
  border: 1px solid orange;
  z-index: 2;
}
}






  </style>
  