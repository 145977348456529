<template>
    <div class="container_one" style="background-color: #F6F7F8;">
    
        
        <el-row>
        <el-col :span="24"><div class="csbg">
            <!-- <img src="..\\src\\static\\web\\解决方案-建材行业\\编组 21.png" width="100%"> -->
            <div class="text1" style="text-align: left; padding:10px;width: 400px;">
            <span style="font-size:35px;color:orange;"><b>智慧城市</b><br><br></span>
            <span style="font-size:15px;color:#ffffff;">利用先进的信息技术、以最小的资源消耗和环境退化为代价，实现最大化的城市经济效率和美好的生活品质
<br></span>
            </div>
        </div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>解决方案</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">SOLUTION<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        
        <el-row :gutter="80">
        <el-col :span="6"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">01.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;text-align: center;">城市大脑</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">利用实时全量的城市数据资源全局优化城市公共资源，即时修正城市运行缺陷，实现政务服务、产业发展和民生服务的三重突破</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;200px;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;">
                    <span style="font-weight: bold;font-size:25px;color:#f78503;">02.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">智慧政务</span><br><br>
                </div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">专注于政务的协同办公平台。以大数据、物联网、云计算为基础，面向公民和企业提供无缝对接的政府公共服务</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">03.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">智慧医疗</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">在云计算和大数据技术的支持下，开展互联网+医院平台建设及运营模式，保证数据可分析，实现数据互联互通，推动智慧医保服务链生态化</span>
            </div>
            </div></el-col>


        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="80">
        <el-col :span="6"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">04.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">智慧金融</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">提供基于大数据、云计算为基础的，面向非法集资案件应用业务平台，协助执法机关提升处理效率，保障投资者合法权益</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;200px;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"> <span style="font-weight: bold;font-size:25px;color:#f78503;">05.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">智慧社区</span><br><br></div>
               
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">基于“互联网+智慧社区”，将社区内有关的政府职能、业主服务、区域经济信息等，通过社区的数字化平台融为一体，为老百姓统一提供社区政务和公共服务，让“百姓少跑腿，信息多跑路”</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;">   <span style="font-weight: bold;font-size:25px;color:#f78503;">06.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">智慧交通</span><br><br></div>
             
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">秉承互联网+交通理念，致力于为交警提供交通大数据融合、大数据分析、大数据可视化产品与服务，互联网实时路况及专业交通研判算法，为交警实战指挥提供技术支持，以提升城市道路治理效能，保障道路交通畅通，提高公众服务水平</span>
            </div>
            </div></el-col>


        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>产品优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">PRODUCT SUPERIORITY<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>



        <el-row :gutter="80">
        <el-col :span="6"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:100%;height:330px;padding:20px">
            <br><br>
            
               <img src="..\\src\\static\\web\\解决方案-汽车行业\\编组 22@2x.png" width="120px" height="120px">
           
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">平台优势</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">多方面提升客户体验</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">拥有成熟、开放的智慧城市核心运行平台，具备强大的软硬件系统集成能力，与众多合作伙伴打造一体化智慧城市运行平台</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;200px;border-radius: 10px;width:100%;height:330px;padding:20px">
            <br><br>
          
               <img src="..\\src\\static\\web\\解决方案-汽车行业\\编组 19@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">产品优势</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">精准分析、明确定位</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">以人为本为核心，以大数据为灵魂的系列解决方案，提供政务、产业、民生成熟产品</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:100%;height:330px;padding:20px">
            <br><br>
           
               <img src="..\\src\\static\\web\\解决方案-汽车行业\\编组 17@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">技术优势</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">提升服务运营效率</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">掌握大数据、云计算、物联网等关键技术，夯实城市信息化基础</span></div>
                
            </div>
            </div></el-col>


        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>






       
    
    </div>
    </template>
      
    <style>
    .csbg{
        height: 400px;
        position: relative;
        background-image: url('../static/web/解决方案-建材行业/编组 21.png');
        background-size: cover;
        background-position: center;
        .text1{
            position: absolute;
            top: 25%;
            left: 25%;
            letter-spacing: 2px;
            line-height: 1.5;
        }
    }
    
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      