<template>
    <div class="container_one">
    
        
        <el-row>
        <el-col :span="24"><div>
            <img src="..\\src\\static\\web\\关于我们\\e5f31a31fcb2ddcb78f958cba8c763f.png" width="100%">
        </div></el-col>
        </el-row><br><br><br><br><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>公司介绍</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">COMPANY PROFILE<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="5"><div>&emsp;</div></el-col>
        <el-col :span="14">
            <div style="text-align: left; padding:10px">
            <span style="font-size:15px;color:#0e0d0da2;">
                大微天成是一家面向全国的IT咨询、系统集成、产品、解决方案和人工智能服务的高科技公司。公司成立于2019年，总部位于中国北京，其团队核心成员来自北大、人大和中国科学院，在大数据、人工智能、云计算和物联网等方面积累了多项核心技术，业务主要围绕 “政务、产业、民生” 三大核心领域，旨在为智慧城市、数智乡村、智慧校园和企事业单位客户提供完整的 “数智化” 解决方案和流程。大微天成遵循严格的质量和安全标准，实施严密的安全措施，拥有成熟可靠的管理和开发流程，并获得相关资质认证和软件发明专利。公司凭借深厚的行业专长、成熟的行业实践、强大的团队背景、完善的人才管理以及无处不在的创新精神与实践，助力全国客户尽享科技革新所带来的全新体验，为客户持续创造关键价值。
                <br><br><br>
                <el-button style="color: rgb(247, 246, 246);background-color: rgb(243, 132, 52);" @click="scrollToBottom()">了解更多</el-button>
                <br><br>
            </span>
            </div>
        </el-col>
        <el-col :span="5"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br><br><br>

        <el-row style="background-color: #F0F1F2">
        <el-col :span="12"><div class="bg_img">
            <!-- <img src="..\\src\\static\\web\\关于我们\\9d742abdb6a0763abb1e8f3a74030c0.png" width="100%" height="550px"> -->
            <div class="tit2">
            <span style="font-size:35px;color:#e0dede;"><b>发展历程</b><br></span>
            <span style="font-size:15px;color:#e0dede;">DEVELOPMENT HISTORY<br></span>
        
            </div>
        </div></el-col>
        <el-col :span="12">
            <div class="centered-container" style="background-color: #F0F1F2;width:100%;height:550px;padding: 10px;">
                    <br>
                    <h4 style="text-align: left;color: rgb(153, 142, 41);">成立</h4>
                    <h5 style="text-align: left;">2019年公司成立</h5>
                    <h4 style="text-align: left;color: rgb(153, 142, 41);">自立品牌求发展</h4>
                    <h5 style="text-align: left;">2020年首款全模块人工智能人力资源管理平台—人智汇上线</h5>
                    <h4 style="text-align: left;color: rgb(153, 142, 41);">主动应变，协同合作</h4>
                    <h5 style="text-align: left;">2021年与双一流高校、一流院所建立合作，深耕大数据、人工智能、企业、政府数智化领域业务产品线研发</h5>
                    <h4 style="text-align: left;color: rgb(153, 142, 41);">产业战略布局</h4>
                    <h5 style="text-align: left;">2022-至今依托研发团队优质资源和标准的交付体系成立智慧城市交付部、智慧高校交付部、数字乡村交付部、
                        数字政府交付部进行产业化布局</h5>
                </div>
        </el-col>
        </el-row><br><br><br><br><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b> 我们的优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">OUR STRENGTHS<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
    <el-col :span="5"><div>&emsp;</div></el-col>
    <el-col :span="14">
        <div class="container">
        <div @click="num = 1" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\快 速@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 24@2x.png" width="60px">
        </div>
        <div v-if="num == 1" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">针对客户个性化需求<br>,打造专属解决方案</span>
            <span class="one">应对行业角色不同的业务功能需求</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>
        <div @click="num = 2" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\专 业@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 27@2x.png" width="60px">
        </div>
        <div v-if="num == 2" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">我们有多年多领域各种</span>
            <span class="one">产品研发经验</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>
        <div @click="num = 3" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\质 量@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 30@2x.png" width="60px">
        </div>
        <div v-if="num == 3" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">追求精细化的服务<br>,力求每个环节做精做细</span>
            <span class="one">给客户一个完美的用户体验</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>
        <div @click="num = 4" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\保 障@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 34@2x.png" width="60px">
        </div>
        <div v-if="num == 4" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">产品在使用过程中出现问题<br>,快速响应</span>
            <span class="one">沟通测试<br>,为您提供专业迅速的服务</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png" width="100px">
            </div>
            </div>
        </div>

    </div></el-col>
    <el-col :span="5"><div>&emsp;</div></el-col>
    </el-row><br><br><br><br>

<!--     
        <el-row>
    <el-col :span="4"><div>&emsp;</div></el-col>
    <el-col :span="16"><div class="container">
        <div @click="num = 1" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\快 速@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 24@2x.png" width="60px">
        </div>
        <div v-if="num == 1" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">针对客户个性化需求打造专属解决方案,</span>
            <span class="one">应对行业,角色不同的业务功能需求</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
            </div>
            </div>
        </div>
        <div @click="num = 2" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\专 业@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 27@2x.png" width="60px">
        </div>
        <div v-if="num == 2" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">针对客户个性化需求打造专属解决方案,</span>
            <span class="one">应对行业,角色不同的业务功能需求</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
            </div>
            </div>
        </div>
        <div @click="num = 3" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\质 量@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 30@2x.png" width="60px">
        </div>
        <div v-if="num == 3" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">针对客户个性化需求打造专属解决方案,</span>
            <span class="one">应对行业,角色不同的业务功能需求</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
            </div>
            </div>
        </div>
        <div @click="num = 4" class="item">
            <br>
            <img src="..\\src\\static\\web\\首页\\保 障@2x.png" width="40px"><br>
            <img src="..\\src\\static\\web\\首页\\编组 34@2x.png" width="60px">
        </div>
        <div v-if="num == 4" class="item2">
            <div class="container">
            <br><br><br>
            <span class="one">针对客户个性化需求打造专属解决方案,</span>
            <span class="one">应对行业,角色不同的业务功能需求</span>

            <div class="image-container">
                <img src="..\\src\\static\\web\\首页\undraw_time_management_re_tk5w.png">
            </div>
            </div>
        </div>

    </div></el-col>
    <el-col :span="4"><div>&emsp;</div></el-col>
    </el-row> -->
    <br><br><br><br>
    
        <!-- <el-row>
        <el-col :span="4"><div>&emsp;</div></el-col>
        <el-col :span="16">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b> 软著登记</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">SOFT WORK REGISTRATION<br></span>
            </div>
        </el-col>
        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>
    
        <el-row>
        <el-col :span="6"><div>&emsp;</div></el-col>
        <el-col :span="12">
            <img src="..\\src\\static\\web\\关于我们\\c216c40c9a1f6d4f20dd7cd657385c5.png" width="100%">
        </el-col>
        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br> -->
    
    </div>
    </template>
      
    <style>

    .bg_img{
        position: relative;
        height: 580px;
        background-image: url('../static/web/关于我们/编组 5.png');
        background-size: cover;
        background-position: center;
    }

    .tit2{
        text-align: right;
        position: absolute;
        right: 50px;
        top: 50px;
    }
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */

    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
        scrollToBottom() {
        var height = document.documentElement.scrollHeight;
        window.scrollTo(0, height);
    }
       
      }
    
    
    };
    </script>
      