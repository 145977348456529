<template>
    <el-container style="background-color:#f3f0f0d3">
    <el-header style="background-color:#f3f0f0d3;height: 80px;">
        <br>
        <el-col :span="15"><div>
            <el-input
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="input2">
            </el-input>
        </div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message-solid" style="color: black;"></i></div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message" style="color: black;"></i></div></el-col>
        <el-col :span="3">
            
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="change-icon"><i class="el-icon-more"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus"><a href="/home">首页</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/rzh">人智汇</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dangjian">党建</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/my">关于我们</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dingzhi">定制开发</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/serve">服务</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/car">解决方案-汽车行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/architecture">解决方案-建筑行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/financial">解决方案-金融行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/retail">解决方案-零售行业</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            
        </el-col>
    </el-header>
    <el-main style="background-color: rgb(250, 248, 248);padding:0;">
        <el-row :gutter="20">
        <el-col :span="24"><div class="grid-content bg-purple"><img src="..\\src\\static\\关于我们\\4a573be3625dee3e3faacab82806738.png" width="100%" height="100%"></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="18">
                <div style="text-align: left;">
                    <h1><font size="10">我们的优势</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">OUR STRENGTHS</font>
                </div>
            </el-col>
            <el-col :span="5"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <div class="container">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="10">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 120px;">
                    
                    <span><img src="..\\src\\static\\yanpanfenxi-shujugaikuang.png" ></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">快速</span>
                    打造专属解决方案
                    
                </div>&nbsp;

            </el-col>

            <el-col :span="10"> 
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 120px;">
                    
                    <span><img src="..\\src\\static\\zuanshi.png" ></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">专业</span>
                    各种技术研发手段
                    
                </div>&nbsp;

            </el-col>
            <el-col :span="2">&emsp;</el-col>
            
        </div>
        </el-row><br>

        <el-row :gutter="20">
            <div class="container">
           
                <el-col :span="2">&emsp;</el-col>
            <el-col :span="10"> 
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 120px;">
                    
                    <span><img src="..\\src\\static\\yanfaguanli-zhiliangbiaozhun.png" ></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">质量</span>
                    打造专属业务需求
                    
                </div>&nbsp;

            </el-col>

            <el-col :span="10">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;height: 120px;">
                        
                       <span><img src="..\\src\\static\\yanfaguanli-zhiliangbiaozhun.png"></span>
                        
                        <span style="font-size: 24px; font-weight: bold;">保障</span>
                        极速呼应迅速服务
                        
                </div>&nbsp;
            </el-col> 
            <el-col :span="2">&emsp;</el-col>
        </div>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">公司介绍</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">COMPANY PROFILE</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>


        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;font-weight: 500;">
                        &nbsp;&nbsp;&nbsp;&nbsp;大微天成是一家面向全国的IT咨询、产品、解决方案与服务的提供商。
                        公司成立于2019年，总部位于中国北京，依托自身强大的研发与创新能力，广泛采用基于大数据、
                        人工智能、云计算和物联网等新兴技术，为教育、金融、高科技、互联网等行业客户提供丰富的解
                        决方案及产品。大微天成遵循严格的质量和安全标准，实施严密的安全措施，拥有成熟可靠的管理
                        和开发流程，并获得CMMI5、ISO9001、ISO14001等一系列资质认证。公司凭借深厚的行业专长和
                        成熟的行业实践、中国科学院和中国科学院大学的精英团队和完善的人才管理、完备的全球化交付与
                        服务网络、以及无处不在的创新精神与实践，助力全球客户尽享科技革新所带来的卓越运营，为客户
                        持续创造关键价值
                    </h4>
                    
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>


        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div>
                    
                        <img src="..\\src\\static\\关于我们\\bddbbbaa845f2c43f62f542957baa58.png">
                        
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="centered-container" >
                    
                   <h4 style="text-align: left;color: rgb(153, 142, 41);">我们的愿景是什么？</h4>
                   <h3 style="text-align: left;font-weight: 500;">科技创新驱动，客户价值至上，方案落地有效</h3>
                   <h4 style="text-align: left;color: rgb(153, 142, 41);">我们的使命是什么？</h4>
                   <h3 style="text-align: left;font-weight: 500;">为客户提供一站式人力资源管理、企业咨询和
                   员工赋能培训等业务范畴的完整解决方案</h3>
                   <h4 style="text-align: left;color: rgb(153, 142, 41);">我们的使命是什么？</h4>
                   <h3 style="text-align: left;font-weight: 500;">长期主义:基于长期价值做决策理性
                   建设性:融入社会，让世界因我变得更好
                   共同体意识:把客户当自己人，紧密互动共同发展
                   头部意识:勇猛精进，保持领先，为客户提供最好的服务</h3>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <!-- <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">软著登记</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">SOFT WORK REGISTRATION</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24">
                <div>
                    
                    <img src="..\\src\\static\\关于我们\\关于我们备份@3x.png" >

                </div>
            </el-col>
        </el-row><br><br> -->

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">联系我们</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CONTACT US</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">公司名称</h4>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的姓名</h4>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的电话</h4>
                <el-input v-model="input" placeholder="请输入联系电话"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">您的电子邮箱</h4>
                    <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>
        
        
        
        <!-- <div style="background-color:#0e0c0ce1;">
            <div class="container">
                &nbsp;&nbsp;<img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&nbsp;尽大至微，卓然天成</h3>
            </div>
            <hr>
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

</div> -->

    </el-main>
    <el-footer style="height: 300px;background-color: #2B2C31">
        <div >
            <div class="container">
                <img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&emsp;&emsp;尽大至微，卓然天成</h3>
        </div>
            <hr size="1">
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

        </div>
    </el-footer>
    </el-container>
</template>
  
  <script>

  export default {
    data() {
      return {

      }
    },
    

  }
  </script>

  <style>
.change-icon{
    font-size:35px;
}

.centered-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center; /* 将内容垂直置中 */
  }

  .box {
    width: 100px;
    height: 100px;
    /* background-color: #EAE5F6; */
    }
.container {
display: flex;
align-items: center; /* 垂直居中对齐 */
}
a {
    text-decoration: none;
    color: #000;
  }

  </style>
  