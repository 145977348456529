<template>
    <el-container style="background-color:#f3f0f0d3">
    <el-header style="background-color:#f3f0f0d3;height: 80px;">
        <br>
        <el-col :span="15"><div>
            <el-input
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="input2">
            </el-input>
        </div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message-solid" style="color: black;"></i></div></el-col>
        <el-col :span="3"><div class="change-icon"><i class="el-icon-message" style="color: black;"></i></div></el-col>
        <el-col :span="3">
            
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="change-icon"><i class="el-icon-more"></i></div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus"><a href="/home">首页</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/rzh">人智汇</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dangjian">党建</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/my">关于我们</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/dingzhi">定制开发</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/serve">服务</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/car">解决方案-汽车行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/architecture">解决方案-建筑行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/financial">解决方案-金融行业</a></el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus"><a href="/retail">解决方案-零售行业</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            
        </el-col>
    </el-header>
    <el-main style="background-color: rgb(250, 248, 248);padding:0;">
        <el-row :gutter="20">
        <el-col :span="24"><div class="grid-content bg-purple"><img src="..\\src\\static\\定制开发\\1c7ce48ce5da38d677727a3fb279de6.png" width="100%" height="100%"></div></el-col>
        </el-row><br><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">开发优势</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">DEVELOPMENT ADVANTAGE</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\0a970a5caeb29e7097af6807b8243c9.png" width="100%" height="100%">
                    
                    
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br><br>
        <!-- <el-row :gutter="20">
            <el-col :span="24">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\22.png" width="100%" height="100%">
                    
                    
                </div>
            </el-col>
        </el-row><br><br> -->


        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">定制流程</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CUSTOM DEVELOPMENGT</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\d07e07111636ef75df398db207d6a20.png" width="100%" height="100%">
                    
                    
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">核心构成</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CORE COMPOSITION</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                    
                        
                        <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\定制开发\\编组 47@3x.png" class="left-img">
                            <span>自主研发</span>
                            
                        </div><hr>
                        
                        产品研发经项目实际验证，拥<br>
                        有国家专利，真正具备核心支<br>
                        持能力<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>


        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\定制开发\\编组 44@3x.png" class="left-img">
                            <span>平台化</span>
                            
                    </div><hr>
                        
                        平台提供完整的升级机制用户<br>
                        可以按需升级，大幅度减少升<br>
                        级成本<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\定制开发\\人工智能备份 3@3x.png" class="left-img">
                            <span>智能化</span>
                            
                    </div><hr>
                        
                        采用自然语言、实时数据等技<br>
                        术实现智能化的数字办公平台<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\定制开发\\编组 44@3x.png" class="left-img">
                            <span>全程数字化</span>
                            
                    </div><hr>
                        
                        积极拥抱微服务、容器化和云原生<br>
                        等新兴技术构建企业级应用平台<br>
                        满足个性化场景开发全程数字化<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                    
                    <div style="font-size: 24px; font-weight: bold;text-align: left;">
                            <img src="..\\src\\static\\定制开发\\编组 41备份@3x.png" class="left-img">
                            <span>结构适配</span>
                            
                    </div><hr>
                        
                        以赋能业务为中心，构建云原生<br>
                        架构版图，来解决业务系统研发<br>
                        过程中的实际问题<br><br>
                        
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="13">
                <div style="text-align: left;">
                    <h1><font size="5">完整的服务体系</font></h1>
                </div>
            </el-col>
            <el-col :span="10"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\编组 5备份@3x.png" width="100%" height="100%">
                    
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="centered-container" >
                    
                   <h3 style="text-align: left;color: rgb(153, 142, 41);">市场营销</h3>
                   <h3 style="text-align: left;font-weight: 500;">与国内双一流高校、一流院所深度合作，为客户打造一站式营销服务平台。</h3>
                   <h3 style="text-align: left;color: rgb(153, 142, 41);">运营管理服务</h3>
                   <h3 style="text-align: left;font-weight: 500;">覆盖全国的服务运营网络，关注客户使用，定期运维。</h3>
                   <h3 style="text-align: left;color: rgb(153, 142, 41);">技术与产品研发</h3>
                   <h3 style="text-align: left;font-weight: 500;">高端技术人才组成的技术与研发团队，保持持续的技术领先。</h3>
                   <h3 style="text-align: left;color: rgb(153, 142, 41);">咨询管理服务</h3>
                   <h3 style="text-align: left;font-weight: 500;">行业技术专家，为用户提供专业的管理体系梳理。</h3>
                   <h3 style="text-align: left;color: rgb(153, 142, 41);">客户支持服务</h3>
                   <h3 style="text-align: left;font-weight: 500;">优质的售后服务，保证24小时紧急联动。</h3>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div style="text-align: left;">
                    <h1><font size="10">产品优势</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">PRODUCT ADVANTAGES</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br><br>

        <el-row :gutter="20">
            <!-- <el-col :span="1">&emsp;</el-col> -->
            <el-col :span="12">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\9f3021d67574877530a35e15bb26781.png" width="100%" height="100%">
                    
                </div>
            </el-col>
            <el-col :span="12">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\7237c5e00246e6b425198b11dddd809.png" width="100%" height="100%">
                    
                </div>
            </el-col>
            <!-- <el-col :span="1">&emsp;</el-col> -->
        </el-row><br>
        <el-row :gutter="20">
            <el-col :span="8">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\ba62297ebfa1e92ae349f132595656d.png" width="100%" height="100%">
                    
                </div>
            </el-col>
            <el-col :span="8">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\5f0085940df1239163cb59ba1ced57d.png" width="100%" height="100%">
                    
                </div>
            </el-col>
            <el-col :span="8">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\a2c16a3ff75569c77b4c4f86266ac8d.png" width="100%" height="100%">
                    
                </div>
            </el-col>
            
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="11">
                <div style="text-align: left;">
                    <h1><font size="10">CRM</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CUSTOMER RELATIONSHIP MANAGEMENT</font>
                </div>
            </el-col>
            <el-col :span="12"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="24">
                <div>
                    
                    <img src="..\\src\\static\\定制开发\\f978ab9c0530a728f5bc77c90d8b640.png">
                    
                </div>
            </el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="centered-container" >
                   <h3 style="text-align: left;color: rgb(224, 131, 24);">CRM管理系统</h3>
                   <h3 style="text-align: left;">集成呼叫中心、第三方ERP、OA视频会议、单点登录、网银在线支付、微信短信、Email身份认证等。</h3>
                    <ul>
                        <li> <h4 style="text-align: left;color: rgba(63, 48, 30, 0.514);">市场活动策划、预算审批、活动执行、<br>市场ROI分析</h4></li>
                        <li> <h4 style="text-align: left;color: rgba(63, 48, 30, 0.514);">多渠道线索获取、验证分发、智能评分、精<br>细化培育</h4></li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="18">
                <div style="text-align: left;">
                    <h1><font size="10">ERP进销存</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">ERP PUECHASE SALE INVENTORY</font>
                </div>
            </el-col>
            <el-col :span="3"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">
                <div class="centered-container" >
                   <h3 style="text-align: left;color: rgb(224, 131, 24);">EPR进销存</h3>
                   <h3 style="text-align: left;font-weight: 500;">面向各类单位及多组织企业采购/销售/库存人员提供全面供应链管理平台</h3>
                </div>
            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>

        

        <el-row :gutter="20">
            <div class="container">
                <el-col :span="1">&emsp;</el-col>
                <el-col :span="11">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;" h>
                    
                    <span><img src="..\\src\\static\\定制开发\\快递代领 2备份@3x.png"  width="90px" height="80px"></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">采购管理</span>

                    
                </div>

            </el-col>

            <el-col :span="11"> 
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                    
                    <span><img src="..\\src\\static\\定制开发\\校区官网 2备份@3x.png"  width="90px" height="80px"></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">销售管理</span>
 
                    
                </div>

            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </div>
        </el-row><br>

        <el-row :gutter="20">
            <div class="container">
                <el-col :span="1">&emsp;</el-col>
                <el-col :span="11">
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;" h>
                    
                    <span><img src="..\\src\\static\\定制开发\\编组 32备份@3x.png"  width="90px" height="80px"></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">库存管理</span>

                    
                </div>

            </el-col>

            <el-col :span="11"> 
                <div class="centered-container" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                    
                    <span><img src="..\\src\\static\\定制开发\\体测计算 2备份@3x.png"  width="90px" height="80px"></span>
                    
                    <span style="font-size: 24px; font-weight: bold;">存货核算</span>
 
                    
                </div>

            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </div>
        </el-row><br>


        

        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="22">

                   <h4 style="text-align: left;color: rgba(36, 30, 23, 0.295);">采购管理: 采购订单、采购价目、收退料管理等</h4>
                   <h4 style="text-align: left;color: rgba(36, 30, 23, 0.295);">销售管理: 销售订单、销售价格、产品发货/退货管理等</h4>

            </el-col>
            <el-col :span="1">&emsp;</el-col>
        </el-row><br>


        <el-row :gutter="20">
            <el-col :span="1">&emsp;</el-col>
            <el-col :span="16">
                <div>
                    <h1><font size="10">联系我们</font></h1>
                    <font size="5" style="color: rgba(9, 10, 12, 0.527); text-align: left;">CONTACT US</font>
                </div>
            </el-col>
            <el-col :span="7"><div></div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">公司名称</h4>
                <el-input v-model="input" placeholder="请输入公司名称"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的姓名</h4>
                <el-input v-model="input" placeholder="请输入您的姓名"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                <h4 style="text-align: left;">您的电话</h4>
                <el-input v-model="input" placeholder="请输入联系电话"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="2">&emsp;</el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple">
                    <h4 style="text-align: left;">您的电子邮箱</h4>
                    <el-input v-model="input" placeholder="请输入您的电子邮箱"></el-input>
                </div>
            </el-col>
            <el-col :span="2">&emsp;</el-col>
        </el-row><br><br>
        
        
        
        <!-- <div style="background-color:#0e0c0ce1;">
            <div class="container">
                &nbsp;&nbsp;<img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&nbsp;尽大至微，卓然天成</h3>
            </div>
            <hr>
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

</div> -->

    </el-main>
    <el-footer style="height: 300px;background-color: #2B2C31">
        <div >
            <div class="container">
                <img src="..\\src\\static\\编组 3.png" width="40%">
                <h3 style="text-align: right; color:#fcfafac5;">&emsp;&emsp;尽大至微，卓然天成</h3>
        </div>
            <hr size="1">
        <div class="container">
            <div style="text-align: left; color:#fcfafac5;padding:10px">
        <h4>联系方式</h4>
        <h5>公司电话: 010-6966 6930</h5>
        <h5>公司邮箱: sales@dwtcyl.com</h5>
        <h5>公司地址: 北京市怀柔区怀柔科学城创新小镇雨林空间206</h5>
        </div>
        </div>

        </div>
    </el-footer>
    </el-container>
</template>
  
  <script>

  export default {
    data() {
      return {

      }
    },
    

  }
  </script>

  <style>
.change-icon{
    font-size:35px;
}

.centered-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center; /* 将内容垂直置中 */
  }

  .box {
    width: 100px;
    height: 100px;
    /* background-color: #EAE5F6; */
    }
.container {
display: flex;
align-items: center; /* 垂直居中对齐 */
}
ul {
    list-style-type: disc;
    color: rgb(235, 144, 7);
  }

  a {
    text-decoration: none;
    color: #000;
  }
  </style>
  