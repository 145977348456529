<template>
    <div class="container_one" style="background-color: #F6F7F8;">
    
        
        <el-row>
        <el-col :span="24"><div>
            <img src="..\\src\\static\\web\\关于我们\\e5f31a31fcb2ddcb78f958cba8c763f.png" width="100%">
        </div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>开发优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">DEVELOPMENT ADVANTAGE<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="6"><div>&emsp;</div></el-col>
        <el-col :span="12">
            <div>
            <img src="..\\src\\static\\web\\定制开发\\d8685e80b7adb59a44e7e45682ca7db.png" width="100%">
            </div>
        </el-col>
        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br>


        <el-row  style="background-color: #EDEEF1;">
        <el-col :span="6"><div>&emsp;</div></el-col>
        <el-col :span="12"><br><br>
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>定制流程</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">CUSTOM DEVELOPMENGT<br></span>
            </div>
            <div style="background-color: #EDEEF1;">
            <img src="..\\src\\static\\web\\定制开发\\684c26391097ce9cdf9ae57a1cf06ef.png" width="100%">
            </div><br><br>
        </el-col>
        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>核心构成</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">CORE COMPOSITION<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="60">
        <el-col :span="3"><div>&emsp;</div></el-col>
        <el-col :span="6"><div>
            <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:120px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <img src="..\\src\\static\\web\\定制开发\\编组 47@3x.png">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 22px;">自主研发</span><br>
                    <span style="font-size: 15px;color: rgba(0, 0, 0, 0.507);">autonomously</span>
                </div>
                
                    <span><br><br><br><br><br>产品研发经项目实际验证，拥有国家专利，真正具备核心支持能力</span>
            </div>
           </div>
        </div></el-col>
        <el-col :span="6"><div>
           <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:120px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <img src="..\\src\\static\\web\\定制开发\\编组 44备份@3x.png">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 22px;">平台化</span><br>
                    <span style="font-size: 15px;color: rgba(0, 0, 0, 0.507);">platform</span>
                </div>
                
                    <span><br><br><br><br><br>平台提供完整的升级机制，用户可以按需升级，大幅度减少升级成本</span>
            </div>
           </div>
        </div></el-col>
        <el-col :span="6"><div>
            <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:120px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <img src="..\\src\\static\\web\\定制开发\\人工智能备份 3@3x.png">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 22px;">智能化</span><br>
                    <span style="font-size: 15px;color: rgba(0, 0, 0, 0.507);">intelligentize</span>
                </div>
                
                    <span><br><br><br><br><br>采用自然语言、实时数据等技术实现智能化的数字办公平台</span>
            </div>
           </div>
        </div></el-col>
        <el-col :span="3"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="60">
        <el-col :span="3"><div>&emsp;</div></el-col>
        <el-col :span="9"><div>
            <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:120px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <img src="..\\src\\static\\web\\定制开发\\编组 44备份@3x.png">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 22px;">全程数字化</span><br>
                    <span style="font-size: 15px;color: rgba(0, 0, 0, 0.507);">digitization</span>
                </div>
                
                    <span><br><br><br><br><br>积极拥抱微服务、容器和云原生等新兴技术构建企业级应用平台，满足个性化场景开发全程数字化</span>
            </div>
           </div>
        </div></el-col>
        <el-col :span="9"><div>
           <div style="width: 100%;background-color: rgb(255, 255, 255);padding: 20px;height:120px;border-radius: 10px; ">
            <div style="text-align: left;">
                <div style="float: left;">
                    <img src="..\\src\\static\\web\\定制开发\\编组 41备份@3x.png">
                </div>
                <div style="text-align: left;float: left; padding: 15px;">
                    <span style="font-size: 22px;">结构适配</span><br>
                    <span style="font-size: 15px;color: rgba(0, 0, 0, 0.507);">structure Adaptive</span>
                </div>
                
                    <span><br><br><br><br><br>以赋能业务为中心，构建云原生架构版图，来解决业务系统研发过程中的实际问题</span>
            </div>
           </div>
        </div></el-col>

        <el-col :span="3"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br><br><br>

        <el-row>
            <el-col :span="3"><div>&emsp;</div></el-col>
        <el-col :span="9"><div>
           <div>
            <div>
                <img src="..\\src\\static\\web\\定制开发\\编组 5@2x(1).png">
            </div>
           </div>
        </div></el-col>
        <el-col :span="9">
            <div class="centered-container" style="text-align: left;">
                    
                
                    <span style="text-align: left;color: rgb(153, 142, 41);">市场营销</span><br>
                    <span>与国内双一流高校、一流院所深度合作，为客户打造一站式营销服务平台</span><br>

                    <span style="text-align: left;color: rgb(153, 142, 41);">运营管理服务</span><br>
                    <span>覆盖全国的服务运营网络，关注客户使用，定期运维</span><br>
                    <span style="text-align: left;color: rgb(153, 142, 41);">技术与产品研发</span><br>
                    <span>高端技术人才组成的技术与研发团队，保持持续的技术领先</span><br>
                    <span style="text-align: left;color: rgb(153, 142, 41);">咨询管理服务</span><br>
                    <span>行业技术专家，为用户提供专业的管理体系梳理</span><br>
                    <span style="text-align: left;color: rgb(153, 142, 41);">客户支持服务</span><br>
                    <span>优质的售后服务，保证24小时紧急联动</span>
            </div>
        </el-col>
        <el-col :span="3"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br><br><br>

        <el-row style="background-color: #EDEEF1;">
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>产品优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">PRODUCT ADVANTAGES<br></span>
            </div><br><br>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row>


        <el-row  style="background-color: #EDEEF1;">
        <el-col :span="2"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #EDEEF1;float: left;">
            <img src="..\\src\\static\\web\\定制开发\\椭圆形 2@2x.png" width="220px" height="220px"><br><br>
            <span style="font-size:15px;color:#0e0d0db4;">
                        个人定制</span><br><br><br><br>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #EDEEF1;float: left;">
            <img src="..\\src\\static\\web\\定制开发\\椭圆形备份 8@2x.png" width="220px" height="220px"><br><br>
            <span style="font-size:15px;color:#0e0d0db4;">
                        方便实用</span>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #EDEEF1;float: left;">
            <img src="..\\src\\static\\web\\定制开发\\椭圆形备份 9@2x.png" width="220px" height="220px"><br><br>
            <span style="font-size:15px;color:#0e0d0db4;">
                        系统集成</span>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #EDEEF1;float: left;">
            <img src="..\\src\\static\\web\\定制开发\\椭圆形备份 10@2x.png" width="220px" height="220px"><br><br>
            <span style="font-size:15px;color:#0e0d0db4;">
                        售后服务</span>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #EDEEF1;float: left;">
            <img src="..\\src\\static\\web\\定制开发\\椭圆形备份 11@2x.png" width="220px" height="220px"><br><br>
            <span style="font-size:15px;color:#0c0c0cb4;">
                        可扩展性</span>
            </div></el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row>
        <el-row style="background-color: #EDEEF1;">
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>

    
        <!-- <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>CRM</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">CUSTOMER RELATIONSHIP MANAGEMENT<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:15px;color:#f78503;"><b>CRM管理系统</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0de1;">集成呼叫中心、第三方ERP、OA视频会议、单点登录、网银在线支付、微信短信、Email、身份认证等。<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="6"><div>&emsp;</div></el-col>

        <el-col :span="12">
            <div style="padding:10px;">
                <img src="..\\src\\static\\web\\定制开发\\279f1b18101bf33da839e2f2311e61a.png" width="100%" height="100%">
            </div>
        </el-col>

        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br> -->

        
        <!-- <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>ERP进销存</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">ERP PUECHASE SALE INVENTORY<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:15px;color:#f78503;"><b>ERP进销存</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0de1;">面向各类单位及多组织企业采购/销售/库存人员提供全面供应链管理平台<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        
        <el-row :gutter="60">
        <el-col :span="4"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div class="bot" style="background-color: #F6F7F8;float: left;width:100%;height:200px;">
            <br><br>
            <div>
               <img src="..\\src\\static\\web\\定制开发\\快递代领 2备份@3x.png"> 
            </div>
            <div>
               <span>采购管理</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div class="bot" style="background-color: #F6F7F8;float: left;width:100%;height:200px;">
            <br><br>
            <div>
               <img src="..\\src\\static\\web\\定制开发\\校区官网 2备份@3x.png"> 
            </div>
            <div>
               <span>销售管理</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div class="bot" style="background-color: #F6F7F8;float: left;width:100%;height:200px;">
            <br><br>
            <div>
               <img src="..\\src\\static\\web\\定制开发\\体测计算 2备份@3x.png"> 
            </div>
            <div>
               <span>库存管理</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div class="bot" style="background-color: #F6F7F8;float: left;width:100%;height:200px;">
            <br><br>
            <div>
               <img src="..\\src\\static\\web\\定制开发\\快递代领 2备份@3x.png"> 
            </div>
            <div>
               <span>存货核算</span>
            </div>
            </div></el-col>
    
        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>

        <el-row>
        <el-col :span="9"><div>&emsp;</div></el-col>
        <el-col :span="6">
            <div style="text-align: left; padding:10px">

            <span style="font-size:15px;color:#0e0d0d6c;">采购管理：采购订单、采购价目、收退料管理等<br><br></span>
            <span style="font-size:15px;color:#0e0d0d6c;">销售管理：销售订单、销售价格、产品发货/退货管理等<br></span>
            </div>
        </el-col>
        <el-col :span="9"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br> -->





       
    
    </div>
    </template>
      
    <style>

.bot {
  border-right: 2px solid rgb(173, 172, 172); /* 设置右边框样式 */
  box-shadow: 5px 0 10px rgb(163, 163, 163); /* 设置阴影样式 */
}
    
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      