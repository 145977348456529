<template>
    <div class="container_one">
    
        
        <el-row>
        <el-col :span="24"><div>
            <img src="..\\src\\static\\web\\党建\\2cf096a085bf487314512b1e8336af3.png" width="100%">
        </div></el-col>
        </el-row><br><br><br>
    
        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>党建信息化管理系统</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">PARTY BULDING INFORMATION MANAGEMENT PLATFORM<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="4"><div>&emsp;</div></el-col>
        <el-col :span="16">
            <div style="text-align: left;">
            <span style="font-size:15px;color:#0e0d0db4;">
                &emsp;&emsp;采用信息技术进行前端界面设计以及实现，构建了党校培训过程管理系统，强化了党校培训过程管理，
                增强党校培训的吸引力与有效性，同时丰富党建信息化平台内容，推动党建信息化水平。<br>
            </span>
            </div>

        </el-col>
        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br><br>
    
        <el-row>
        <el-col :span="4"><div>&emsp;</div></el-col>
        <el-col :span="16">
            <img src="..\\src\\static\\web\\党建\\3977ce08bb30946c1339ddc751b5e2a.png" width="100%">
        </el-col>
        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>
        
        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>功能服务</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">FUNCTIONAL SERVICE<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="4"><div>&emsp;</div></el-col>
        <el-col :span="16">
            <div style="text-align: left;">
                <span style="font-size:15px;color:#0e0d0db4;">
                &emsp;&emsp;党建信息化管理平台是基于云计算技术的解决方案，旨在打造一个集党建宣传、
                党建工作管理、党员服务、党员学习、互动交流、数据统计分析为一体的智慧化
                平台。通过统一标准、整合资源、优化业务流程、共享网络服务，使得党建工作
                信息化投资更加节约、性能更加优越、信息更加集成、业务更加协同。<br>
            </span>
            </div>
        </el-col>
        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br><br>
    
        <el-row>
        <el-col :span="4"><div>&emsp;</div></el-col>
        <el-col :span="16">
            <img src="..\\src\\static\\web\\党建\\f9c66f52c4b81d302fceb3b8e543254.png" width="100%">
        </el-col>
        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>
    
        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>核心优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">CORE ADVANTAGES<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>
    
        <el-row>
        <el-col :span="5"><div>&emsp;</div></el-col>
        <el-col :span="14">
            <img src="..\\src\\static\\web\\党建\\6d4a6ede64acc973d934dc85227459a.png" width="100%">
        </el-col>
        <el-col :span="5"><div>&emsp;</div></el-col>
        </el-row><br><br><br>

        

    
    </div>
    </template>
      
    <style>
    
    .container_one {
      box-sizing: border-box;
      border: none;
      background-color:#F6F7F8;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */

    .section_12 {
  position: absolute;
  left: 579px;
  top: 2134px;
  width: 744px;
  height: 660px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng60aef069b43ea6d44f191a2d600f4398c44af20879176c80514242151b0edd7a)
    100% no-repeat;
  background-size: 100% 100%;
}

.label_1 {
  width: 38px;
  height: 32px;
  margin: 94px 0 0 353px;
}

.text-group_8 {
  width: 192px;
  height: 82px;
  margin: 58px 0 0 283px;
}

.text_59 {
  width: 156px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-left: 12px;
}

.paragraph_4 {
  width: 192px;
  height: 44px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: right;
  line-height: 22px;
  margin-top: 10px;
}

.text-wrapper_23 {
  background-color: rgba(131, 47, 47, 1);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 106px 0 0 323px;
}

.paragraph_5 {
  width: 48px;
  height: 66px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 33px;
  margin: 14px 0 0 24px;
}

.list_1 {
  width: 584px;
  height: 82px;
  justify-content: space-between;
  margin: 40px 0 66px 77px;
}

.text-group_9-0 {
  width: 208px;
  height: 82px;
  margin-right: 184px;
}

.text_60-0 {
  width: 160px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-left: 24px;
}

.text_61-0 {
  width: 208px;
  height: 44px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: right;
  line-height: 22px;
  margin-top: 10px;
}

.text-group_9-1 {
  width: 208px;
  height: 82px;
  margin-right: 184px;
}

.text_60-1 {
  width: 160px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-left: 24px;
}

.text_61-1 {
  width: 208px;
  height: 44px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: right;
  line-height: 22px;
  margin-top: 10px;
}
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      