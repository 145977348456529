<template>
    <div class="container_one">
    
        
        <el-row>
        <el-col :span="24"><div>
            <img src="..\\src\\static\\web\\人智汇\\89af4f6eac1555b1a08c4593b50eeef.png" width="100%">
        </div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>人智汇</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">FUNCTION ANALYSIS<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="4"><div>&emsp;</div></el-col>
        <el-col :span="16">
            <div style="text-align: left;">
            <span style="font-size:15px;color:#0e0d0db4;">
                &emsp;&emsp;人智汇通过专业的人力资源管理核心平台，实现六大模块的全面链接，为企业打造覆盖核心人力事务、员工服务、人才运营、数智决策等模块的一站式服务，推动组织数字化变革与战略目标的达成。帮助企业建立完善的人力资源体系流程，达到降本增效的目的<br>
            </span>
            </div>

        </el-col>
        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br><br>
    
        <el-row>
        <el-col :span="3"><div>&emsp;</div></el-col>
        <el-col :span="6">

                 <div class="div111" style="text-align: left; padding:10px;width: 95%;">
                    <!-- align-items: flex-end;justify-content: center;display: flex; -->
                <!-- <div style="float:left; height: 150px">  -->
                    <div class="div2"><img src="..\\src\\static\\web\\人智汇\\编组 13@3x.png"></div>
                <!-- </div> -->
                
                <div  class="div3" style="text-align: left; padding:30px;background-color: rgb(228, 230, 230);height: 120px;width: 250px;float:left;border-radius: 10px;">
                    <br>
                    <span>
                        <span style="font-size:18px;color:#0e0d0d;"><b>资源规划</b></span>
                        <img src="..\\src\\static\\web\\人智汇\\编组 26备份@3x.png" class="right-img" width="40px" height="40px">
                    </span><br><br>
                    <span style="font-size:12px;color:#0e0d0db4;">
                        &emsp;&emsp;以企业视角规划人力资源，调整组织架构</span>
                </div>
            </div>

        </el-col>
        <el-col :span="6">

            <div  class="div111" style="text-align: left; padding:10px;width: 95%;">
                <div  class="div2">
                    <img src="..\\src\\static\\web\\人智汇\\编组 22@3x.png">
                </div>
                
                <div  class="div3" style="text-align: left; padding:30px;background-color: rgb(228, 230, 230);height: 120px;width: 250px;float:left;border-radius: 10px;">
                    <br>
                    <span>
                        <span style="font-size:18px;color:#0e0d0d;"><b>绩效管理</b></span>
                        <img src="..\\src\\static\\web\\人智汇\\编组 24备份@3x.png" class="right-img" width="40px" height="40px">
                    </span><br><br>
                    <span style="font-size:12px;color:#0e0d0db4;">
                        &emsp;&emsp;回归绩效管理本质，帮助企业有效地落地战略</span>
                </div>
            </div>
        </el-col>

        <el-col :span="6">
            <div  class="div111" style="text-align: left; padding:10px;width: 95%;">
            <!-- align-items: flex-end;justify-content: center;display: flex; -->
            <div  class="div2"> 
            <img src="..\\src\\static\\web\\人智汇\\编组 23@3x.png">
            </div>

            <div  class="div3" style="text-align: left; padding:30px;background-color: rgb(228, 230, 230);height: 120px;width: 250px;float:left;border-radius: 10px;">
            <br>
            <span>
                <span style="font-size:18px;color:#0e0d0d;"><b>员工关系</b></span>
                <img src="..\\src\\static\\web\\人智汇\\编组 23备份@3x.png" class="right-img" width="40px" height="40px">
            </span><br><br>
            <span style="font-size:12px;color:#0e0d0db4;">
                &emsp;&emsp;动态呈现人才成长轨迹生成人才地图</span>
            </div>
            </div>
            </el-col>
            
        <el-col :span="3"><div>&emsp;</div></el-col>
        </el-row><br><br><br>

        <el-row>
        <el-col :span="3"><div>&emsp;</div></el-col>
        <el-col :span="6">

                 <div  class="div111" style="text-align: left; padding:10px;width: 95%;">
                    <!-- align-items: flex-end;justify-content: center;display: flex; -->
                <div  class="div2"> 
                    <img src="..\\src\\static\\web\\人智汇\\编组 24@3x.png">
                </div>
                
                <div  class="div3" style="text-align: left; padding:30px;background-color: rgb(228, 230, 230);height: 120px;width: 250px;float:left;border-radius: 10px;">
                    <br>
                    <span>
                        <span style="font-size:18px;color:#0e0d0d;"><b>薪酬福利</b></span>
                        <img src="..\\src\\static\\web\\人智汇\\编组 28@3x.png" class="right-img" width="40px" height="40px">
                    </span><br><br>
                    <span style="font-size:12px;color:#0e0d0db4;">
                        &emsp;&emsp;多类员工、多类模式的薪酬核算体系</span>
                </div>
            </div>

        </el-col>
        <el-col :span="6">

            <div  class="div111" style="text-align: left; padding:10px;width: 95%;">
                <div  class="div2">
                    <img src="..\\src\\static\\web\\人智汇\\编组 25@3x.png">
                </div>
                
                <div  class="div3" style="text-align: left; padding:30px;background-color: rgb(228, 230, 230);height: 120px;width: 250px;float:left;border-radius: 10px;">
                    <br>
                    <span>
                        <span style="font-size:18px;color:#0e0d0d;"><b>招聘管理</b></span>
                        <img src="..\\src\\static\\web\\人智汇\\编组 32备份@3x.png" class="right-img" width="40px" height="40px">
                    </span><br><br>
                    <span style="font-size:12px;color:#0e0d0db4;">
                        &emsp;&emsp;智能招聘全流程管理，提高招聘效率</span>
                </div>
            </div>
        </el-col>

        <el-col :span="6">
            <div  class="div111" style="text-align: left; padding:10px;width: 95%;">
            <!-- align-items: flex-end;justify-content: center;display: flex; -->
            <div  class="div2"> 
            <img src="..\\src\\static\\web\\人智汇\\编组 27@3x.png">
            </div>

            <div  class="div3" style="text-align: left; padding:30px;background-color: rgb(228, 230, 230);height: 120px;width: 250px;float:left;border-radius: 10px;">
            <br>
            <span>
                <span style="font-size:18px;color:#0e0d0d;"><b>培训开发</b></span>
                <img src="..\\src\\static\\web\\人智汇\\编组 44备份@3x.png" class="right-img" width="40px" height="40px">
            </span><br><br>
            <span style="font-size:12px;color:#0e0d0db4;">
                &emsp;&emsp;结合企业战略的培训地图，定制培训方案</span>
            </div>
            </div>
            </el-col>
            
        <el-col :span="3"><div>&emsp;</div></el-col>
        </el-row><br><br><br>
        
        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>产品优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">FUNCTION ANALYSIS<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="20">
            <el-col :span="3"><div>&emsp;</div></el-col>
            <el-col :span="6">
                <div style="border: 0px; border-radius: 30px; padding: 30px;height:420px;width: 80%;background-color: #FFFFFF;">
                    
                    <span><img src="..\\src\\static\\web\\人智汇\\Innovation _Flatline@2x.png" width="250px" height="200px"></span><br><br>
                    
                    <span style="font-size: 24px; font-weight: bold;">技术领先 独家专利</span><br><br><br>
                    <span>融合中科院前沿AI技术，智能化提取简历信息，多角度解析人物画像，精准人岗匹配<br><br></span>
                    
                    
            </div>
            </el-col>
            <el-col :span="6">
                <div style="border: 0px; border-radius: 30px; padding: 30px;height:420px;width: 80%;background-color: #212430;">
                    
                    <span><img src="..\\src\\static\\web\\人智汇\\Data processing _Flatline 2@2x.png"  width="250px" height="200px"></span><br><br>
                    
                    <span style="font-size: 24px; font-weight: bold;color: rgb(243, 241, 241);">首款全模块系统</span><br><br><br>
                    <span style="color: rgb(243, 241, 241);">人智汇人力资源管理平台内置了规划、管理等涉及人力资源管理的全部模块，能够完善企业管理体系</span><br><br>
                    
            </div>
            </el-col>
            <el-col :span="6">
                <div style="border: 0px; border-radius: 30px; padding: 30px;height:420px;width: 80%;background-color: #FFFFFF;">
                    
                    <span><img src="..\\src\\static\\web\\人智汇\\Finance app_Flatline@2x.png"  width="250px" height="200px"></span><br><br>
                    
                    <span style="font-size: 24px; font-weight: bold;">智能分析 数据洞见</span><br><br><br>
                    <span>大数据深度挖掘，多维度可视化表达，以数据支持决策，实现数据智能<br><br></span>
                    
            </div>
            </el-col>
            <el-col :span="3"><div>&emsp;</div></el-col>
        </el-row><br>

        <el-row :gutter="20">
            <el-col :span="6"><div>&emsp;</div></el-col>
            <el-col :span="6">
                <div style="border: 0px; border-radius: 30px; padding: 30px;height:420px;width: 80%;background-color: #FFFFFF;">
                    
                    <span><img src="..\\src\\static\\web\\人智汇\\User interface_Flatline 3@2x.png"  width="250px" height="200px"></span><br><br>
                    
                    <span style="font-size: 24px; font-weight: bold;">贴合业务 便捷协同</span><br><br><br>
                    <span>深度理解人力业务场景，自由搭配组合功能模块，提供包容性业务平台，全周期规划企业资源<br><br></span>
                    
            </div>
            </el-col>
            <el-col :span="6">
                <div style="border: 0px; border-radius: 30px; padding: 30px;height:420px;width: 80%;background-color: #FFFFFF;">
                    
                    <span><img src="..\\src\\static\\web\\人智汇\\Information flow_Flatline@2x.png"  width="250px" height="200px"></span><br><br>
                    
                    <span style="font-size: 24px; font-weight: bold;">ToC交互设计</span><br><br><br>
                    <span>减少页面跳转，提升用户体验，支持服务、事务共享，构建人工智能服务平台<br><br></span>
                    
                    
            </div>
            </el-col>
            <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br>

        
        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>核心价值</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">CORE VALUE<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <el-row :gutter="20">
            <el-col :span="2"><div>&emsp;</div></el-col>
            <el-col :span="5">
                <div class="centered-container" style="border: 0px; border-radius: 30px; padding: 35px;height:200px;width: 80%;background-color: #FFFFFF;">
                    <span style="font-size: 24px; font-weight: bold;">新管控</span><br><br>
                    支持多组织架构、业务协作、政策共享与隔离、管控权限设置<br><br>
                    
                    <span><img src="..\\src\\static\\web\\人智汇\\未命名1704188149.png" ></span>
                    
            </div>
            </el-col>
            <el-col :span="5">
                <div class="centered-container" style="border: 0px; border-radius: 30px; padding: 35px;height:200px;width: 80%;background-color: #FFFFFF;">
                    <span style="font-size: 24px; font-weight: bold;">新共享</span><br><br>
                    基于HR三支柱运营模式的一体化人力共享服务平台<br><br>
                    <span><img src="..\\src\\static\\web\\人智汇\\未命名1704188206.png" ></span>
                    
             
                    
            </div>
            </el-col>
            <el-col :span="5">
                <div class="centered-container" style="border: 0px; border-radius: 30px; padding: 35px;height:200px;width: 80%;background-color: #FFFFFF;">
                    <span style="font-size: 24px; font-weight: bold;">新绩效</span><br><br>
                    战略解码、逐级分解指标实时反馈、激励赋能、智能分析<br><br>
                    <span><img src="..\\src\\static\\web\\人智汇\\未命名1704188233.png" ></span>
                    
             
                    
            </div>
            </el-col>
            <el-col :span="5">
                <div class="centered-container" style="border: 0px; border-radius: 30px; padding: 35px;height:200px;width: 80%;background-color: #FFFFFF;">
                    <span style="font-size: 24px; font-weight: bold;">新智能</span><br><br>
                    全新的人工智能技术融入到HR管理中<br><br>
                    <span><img src="..\\src\\static\\web\\人智汇\\94a367cae1c097bbc3db7af26be68c5.png"  ></span>
                
            </div>
            </el-col>
            <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br><br>

        
    
       
    </div>
    </template>
      
    <style>

    .div111{
        position: relative;
        width: 250px;
        height: 200px;
        .div2{
            position: absolute;
            left: 10px;
            top: 80px;
        }
        .div3{
            position: absolute;
            left: 70px;
        }
    }

    
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      