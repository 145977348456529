<template>
    <div class="container_one" style="background-color: #F6F7F8;">
    
        
        <el-row>
        <el-col :span="24"><div class="jrbg">
            <!-- <img src="..\\src\\static\\web\\解决方案-金融行业\\编组 21.png" width="100%"> -->
            <div class="text1" style="text-align: left; padding:10px;width: 400px;">
            <span style="font-size:35px;color:orange;"><b>数智乡村</b><br><br></span>
            <span style="font-size:15px;color:#ffffff;">按照产业兴旺、生态宜居、乡村文明、治理有效、生活富裕的总体要求，结合基层实际工作情况赋能乡村建设，将数字化、智能化所带来的进步覆盖至每一寸土地
<br></span>
            </div>
        </div></el-col>
        </el-row><br><br>

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>解决方案</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">SOLUTION<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>

        
        <el-row :gutter="100">
        <el-col :span="4"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:200px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:20px;color:#f78503;">01.</span>
                <span style="font-weight: bold;font-size:20px;color:#0a0a0a;">管理驾驶舱</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">助力科学分析决策、指挥调度、监督考核</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;text-align: center;200px;border-radius: 10px;width:100%;height:200px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:20px;color:#f78503;">02.</span>
                <span style="font-weight: bold;font-size:20px;color:#0a0a0a;">乡村服务门户</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">形成对外的“名片”和招商门户</span>
            </div>
            </div></el-col>
            <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:100%;height:200px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:20px;color:#f78503;">03.</span>
                <span style="font-weight: bold;font-size:20px;color:#0a0a0a;">企业服务门户</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">聚合信息、服务、商家、资金、人才、提升营商环境</span>
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;text-align: left;200px;border-radius: 10px;width:100%;height:200px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:20px;color:#f78503;">04.</span>
                <span style="font-weight: bold;font-size:20px;color:#0a0a0a;">N方面数字化平台</span><br><br></div>
                
                <span style="font-size:12px;color:#686767;letter-spacing: 2px;line-height: 1.5;">全面提升各部门数字化管理能力，提升服务效率</span>
            </div>
            </div></el-col>


        <el-col :span="4"><div>&emsp;</div></el-col>
        </el-row><br><br>

        <!-- <el-row :gutter="100">
        <el-col :span="7"><div>&emsp;</div></el-col>

        <el-col :span="5"> <div style="background-color: #ffffff;text-align: left;border-radius: 10px;width:200px;height:200px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">03.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">企业服务门户</span><br><br></div>
                
                <span style="font-size:12px;color:#0a0a0a;letter-spacing: 2px;line-height: 1.5;">聚合信息、服务、商家、资金、人才、提升营商环境</span>
            </div>
            </div></el-col>
        <el-col :span="5"> <div style="background-color: #ffffff;text-align: left;200px;border-radius: 10px;width:200px;height:200px;padding:20px">
            <br><br>
            <div><br>
                <div style="text-align: center;"><span style="font-weight: bold;font-size:25px;color:#f78503;">04.</span>
                <span style="font-weight: bold;font-size:25px;color:#0a0a0a;">N方面数字化平台</span><br><br></div>
                
                <span style="font-size:12px;color:#0a0a0a;letter-spacing: 2px;line-height: 1.5;">全面提升各部门数字化管理能力，提升服务效率</span>
            </div>
            </div></el-col>
     


        <el-col :span="7"><div>&emsp;</div></el-col>
        </el-row><br><br> -->

        <el-row>
        <el-col :span="2"><div>&emsp;</div></el-col>
        <el-col :span="20">
            <div style="text-align: center; padding:10px">
            <span style="font-size:30px;color:#0e0d0d;"><b>产品优势</b><br><br></span>
            <span style="font-size:15px;color:#0e0d0da4;">PRODUCT SUPERIORITY<br></span>
            </div>
        </el-col>
        <el-col :span="2"><div>&emsp;</div></el-col>
        </el-row><br><br>



        <el-row :gutter="100">
        <el-col :span="6"><div>&emsp;</div></el-col>

        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
            
               <img src="..\\src\\static\\web\\解决方案-金融行业\\编组 22@2x.png" width="120px" height="120px">
           
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">创新性</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">多方面提升客户体验</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">以 “产业兴旺、生态宜居、乡村文明、治理有效、生活富裕” 为理念构建的全域统一数字平台</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;200px;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
          
               <img src="..\\src\\static\\web\\解决方案-金融行业\\编组 18@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">先进性</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">精准分析、明确定位</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">通过建设业务数据协同应用体系，创设跨部门、跨层级、跨系统的数据开放共享机制</span></div>
                
            </div>
            </div></el-col>
        <el-col :span="4"> <div style="background-color: #ffffff;float: left;border-radius: 10px;width:100%;height:300px;padding:20px">
            <br><br>
           
               <img src="..\\src\\static\\web\\解决方案-金融行业\\编组 17@2x.png" width="120px" height="120px"> 
            
            <div><br>
                <span style="font-weight: bold;font-size:15px;color:#f78503;">落地性</span><br><br>
                <!-- <span style="font-weight: bold;font-size:15px;color:#0a0a0a;">提升服务运营效率</span><br><br> -->
                <div style="text-align: left"><span style="font-size:12px;color:#686767;">聚焦农业农村领域，服务基层实际需求，立足乡村资源禀赋，具备快速推广的能力</span></div>
                
            </div>
            </div></el-col>


        <el-col :span="6"><div>&emsp;</div></el-col>
        </el-row><br><br><br><br>






       
    
    </div>
    </template>
      
    <style>
    .jrbg{
        height: 400px;
        position: relative;
        background-image: url('../static/web/解决方案-金融行业/编组 21.png');
        background-size: cover;
        background-position: center;
        .text1{
            position: absolute;
            top: 25%;
            left: 25%;
            letter-spacing: 2px;
            line-height: 1.5;
        }
    }
    
    .container_one {
      box-sizing: border-box;
      border: none;
    }
    .one {
    
    width: 20px;
    
    margin: 0 auto;
    
    line-height: 24px;
    
    font-size: 20px;
    
    color: rgb(100, 97, 97);
    
    }
    
    
    
    .container {
        display: flex;
        text-align: center;
        
    }
    /* .item {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 100px;
      background-color: rgb(247, 248, 248);
    }
    .item2 {
      flex: 1;
      border: 1px solid rgb(241, 236, 236);
      margin-right: 0px;
      line-height: 200px;
      height: 700px;
      width: 300px;
      background-color: rgb(247, 248, 248);
    } */
    
    
    </style>
    
    <script>
    export default {
    components: {
    },
    name: 'HomeView2',
    data() {
      return {
        num:1,
      }
    },
    methods:{
       
      }
    
    
    };
    </script>
      